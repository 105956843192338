import tw, { styled } from 'twin.macro'
export const ModalBlock = tw.div`
    inline-block
    bg-gray-100 rounded-md 
    border-gray-200
    border-2
    px-4 pt-5 pb-4 text-left  shadow-md transform transition-all
`
export const Container = styled.div`
    ${tw`flex flex-col`}
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 80vh;
`
export const ButtonContainer = tw.div`
    flex 
    flex-col
`
export const Button = tw.button`
    w-full inline-flex justify-center rounded-md border border-transparent shadow-sm 
    py-2 px-2 text-base font-medium 
    text-white
    bg-blue-600 hover:bg-blue-700 focus:outline-none 
    text-xs
    mt-3 
`

export const Label = tw.label`
    block text-sm font-medium text-gray-500 text-xs mt-2 pl-1
`
export const HeaderLabel = tw.label`
    block text-sm font-medium text-gray-700 text-xs mt-2 pl-1 border-green-200 bg-green-100 border-2 py-1 px-2 rounded-md
`

export const Input = tw.input`
    mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-200 border-2 rounded-md py-1 px-2
`
export const Select = tw.select`
    mt-3 block focus:ring-indigo-500 focus:border-indigo-500 w-full sm:max-w-xs sm:text-sm border-gray-200 border-2 rounded-md px-2 py-1
`
