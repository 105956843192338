import React, { Component } from 'react'
import tw, { styled } from 'twin.macro'
import { Link } from 'react-router-dom'

import consentNeeded from '../icons/consentNeed.svg'
import consentGiven from '../icons/consentGiven.svg'
import signingStart from '../icons/signingStart.svg'
import signingComplete from '../icons/signingComplete.svg'
import saveIcon from '../icons/save.svg'

import rightArrow from '../icons/BYA-v2.gif'
import docuSignLogo from '../icons/docuSignLogo.png'
import warningLogo from "../icons/warning-good.png"
import { css } from '@emotion/react'
const Wrapper = styled.div(({ isGoodleap }) =>
    [
        tw`
            bg-white
            fixed
            flex justify-center
            border-b
            border-gray-200
            shadow-sm
        `,
        isGoodleap && { marginTop: "3rem", backgroundColor: '#28489a' }
    ]);

const Container = styled.div`
    ${tw`flex items-center justify-between
    flex-col
    sm:flex-row
    py-2
    sm:py-4
    px-2 sm:px-0`}
    @media (min-width: 480px) {
        width: 92.5%;
    }
`
const Left = tw.div`
    flex-1 min-w-0
`

const MetaContainer = styled.div`
    ${tw`my-2 sm:my-0`}
    display: flex;
    @media (min-width: 1024px) {
        margin-left: 320px;
    }
    @media (min-width: 1180px) {
        margin-left: 320px;
    }
`
const MetaItem = tw.div`
    flex items-center justify-center
    flex-col
    mr-1
    sm:mr-7
`
const Label = styled.div(({ isGoodleap }) => [
    tw`font-light tracking-widest uppercase`,
    {
        color: "#222",
        opacity: 0.5,
        fontSize: "9px",
        textAlign: "center"
    },
    isGoodleap && { color: '#fff' }

])
const Fraction = styled.div(({ isGoodleap }) => [
    tw`rounded-md flex items-center justify-center`,
    {
        width: "63px",
        height: "21px",
        backgroundColor: "rgba(0, 0, 0, 0.06)"
    },
    isGoodleap && { color: '#fff' }

])

const Val = styled.div(({ isGoodleap }) =>
    [
        tw`text-gray-900 font-medium`,
        {
            color: '#222',
            fontSize: '12px'
        },
        isGoodleap && { color: '#fff' }
    ]
)
const Seperator = styled.div`
    ${tw`font-light`}
    color: #222;
    margin: 0 4px;
    font-size: 12px;
`

const Right = tw.div`
    flex
    flex-wrap
    ml-0
    sm:ml-5
    sm:mt-0
`

const ButtonIcon = styled.img`
    margin-right: 7px;
`
const Button = styled.button(({ isGoodleap }) => [
    tw`inline-flex justify-center items-center shadow-sm  text-white focus:outline-none font-normal uppercase tracking-wider ml-2`,
    {
        fontSize: "12px",
        width: "170px",
        height: "38px",
        borderRadius: "30px"
    }

])
const ButtonSave = styled(Button)(({ isGoodleap }) => [
    {
        backgroundColor: "#7fba23",
    },
    isGoodleap && { backgroundColor: '#f6ca4c', color: '#28489a', borderRadius: 0, fontWeight: 700, width: "120px" }
])

const ButtonWhite = styled(Button)(({ isGoodleap }) => [
    tw`bg-white hover:bg-white shadow-none cursor-default`,
    {
        color: "#222",
    },
    isGoodleap && { borderRadius: 0, color: "#fff", fontWeight: 700 },
    isGoodleap && { background: "#28489a" }

])

const TickerWrapper = styled.div`
    background-color: #fc9615;
    position: fixed;
    width: 100%;
    overflow: hidden;
    height: 3rem;
    padding-left: 100%;
    box-sizing: content-box;
    z-index:9999;
    .ticker {
        display: inline-block;
        height: 3rem;
        line-height: 3rem;
        white-space: nowrap;
        padding-right: 100%;
        box-sizing: content-box;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-name: ticker;
        animation-name: ticker;
        -webkit-animation-duration: 100s;
        animation-duration: 100s;

        .item {
            display: inline-block;
            margin-left: 50px;
            margin-right: 50px;
            p{
                ${tw`font-normal `}
                font-size: 1.5rem;
                color: white;
                strong {
                    ${tw`font-bold`}
                }
            }
            img{
                margin-bottom: -0.5rem;
                height: 2rem;
            }
        }

    }`

const Ticker = ({ bottom = false }) => (
    <TickerWrapper style={bottom ? { bottom: 0 } : { top: 0 }}>
        <div className="ticker">
            {
                [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => (
                    <>
                        <div className="item">
                            <p>This screen is a walk through! This <strong>is not</strong> an actual signing experience.</p>
                        </div>
                        <div className="item">
                            <img src={warningLogo} />
                        </div>
                        <div className="item">
                            <p>Signing takes place on the customer's device</p>
                        </div>
                        <div className="item">
                            <img src={warningLogo} />
                        </div>
                    </>
                )
                )}
        </div>
    </TickerWrapper>
)

export default ({
    clientActions = null,
    saveFn,
    attemptingToSave = false,
    autoScrollFn,
    width,
    givenConsent,
    openConsentFn,
    scrollArrow,
    consentModalShown,
    initialModalShown,
    signatureModalShown,
    scale,
    isGoodleap = false, 
    groups = []
}) => {
    const signatures = clientActions && clientActions.filter(c => c.type == 'Signature')
    const initials = clientActions && clientActions.filter(c => c.type == 'Initial')
    const freeText = clientActions && clientActions.filter(c => c.type == 'FreeText' && c.meta && c.meta.required)
    
    const checkboxes = clientActions ? clientActions.filter(c => c.type == 'Checkbox'): []
    const checkboxMetaIds = checkboxes.map(c => c.meta ? c.meta.groupId : 0)
    const groupsThatHaveCheckboxes = groups.filter(g => checkboxMetaIds.includes(g.id))

    const completedCheckboxes = checkboxes.filter(c => c.clicked)
    const completedCheckboxMetaIds = completedCheckboxes.map(c => c.meta ? c.meta.groupId : 0)
    const groupsThatHaveCompletedCheckboxes = groups.filter(g => completedCheckboxMetaIds.includes(g.id))


    const startedSigning =
        (initials && initials.filter(i => i.clicked).length) || (signatures && signatures.filter(s => s.clicked).length)
    return (
        // Top margin is temporary to reverse the top negative margin that is in the app client embed
        <>
            {
                isGoodleap ?
                    <>
                        <Ticker bottom={true} />
                        <Ticker />
                    </>

                    : <></>


            }
            <Wrapper isGoodleap={isGoodleap} style={{ width: '100vw', zIndex: 999 }}>

                <Container>
                    {
                        isGoodleap ?
                            <img src={docuSignLogo} style={{ width: '150px' }} />
                            :
                            <></>
                    }
                    <Left>
                        <MetaContainer>
                            <MetaItem>
                                <Label isGoodleap={isGoodleap}>Initials</Label>
                                {initials && initials.length ? (
                                    <Fraction>
                                        <Val isGoodleap={isGoodleap}>{initials.filter(i => i.clicked).length} </Val>
                                        <Seperator isGoodleap={isGoodleap}>/</Seperator>
                                        <Val isGoodleap={isGoodleap}>{initials.length}</Val>
                                    </Fraction>
                                ) : (
                                    <Fraction>
                                        <Val isGoodleap={isGoodleap}>0</Val>
                                        <Seperator isGoodleap={isGoodleap}>/</Seperator>
                                        <Val isGoodleap={isGoodleap}>0</Val>
                                    </Fraction>
                                )}
                            </MetaItem>

                            <MetaItem>
                                <Label isGoodleap={isGoodleap}>{width < 420 ? 'Sigs' : 'Signatures'}</Label>
                                {signatures && signatures.length ? (
                                    <Fraction>
                                        <Val isGoodleap={isGoodleap}>{signatures.filter(i => i.clicked).length} </Val>
                                        <Seperator isGoodleap={isGoodleap}>/</Seperator>
                                        <Val isGoodleap={isGoodleap}>{signatures.length}</Val>
                                    </Fraction>
                                ) : (
                                    <Fraction>
                                        <Val isGoodleap={isGoodleap}>0</Val>
                                        <Seperator isGoodleap={isGoodleap}>/</Seperator>
                                        <Val isGoodleap={isGoodleap}>0</Val>
                                    </Fraction>
                                )}
                            </MetaItem>
                            {freeText && freeText.length > 0 ? 
                                <MetaItem>
                                    <Label isGoodleap={isGoodleap}>Text Input</Label>
                                    {freeText && freeText.length ? (
                                        <Fraction>
                                            <Val isGoodleap={isGoodleap}>{freeText.filter(i => i.clicked).length} </Val>
                                            <Seperator isGoodleap={isGoodleap}>/</Seperator>
                                            <Val isGoodleap={isGoodleap}>{freeText.length}</Val>
                                        </Fraction>
                                    ) : (
                                        <Fraction>
                                            <Val isGoodleap={isGoodleap}>0</Val>
                                            <Seperator isGoodleap={isGoodleap}>/</Seperator>
                                            <Val isGoodleap={isGoodleap}>0</Val>
                                        </Fraction>
                                    )}
                                </MetaItem>
                                : 
                                <></>
                            }
                            {groupsThatHaveCheckboxes && groupsThatHaveCheckboxes.length > 0 ?
                                <MetaItem>
                                    <Label isGoodleap={isGoodleap}>Checkbox</Label>
                                    {groupsThatHaveCheckboxes && groupsThatHaveCheckboxes.length ? (
                                        <Fraction>
                                            <Val isGoodleap={isGoodleap}>{groupsThatHaveCompletedCheckboxes.length} </Val>
                                            <Seperator isGoodleap={isGoodleap}>/</Seperator>
                                            <Val isGoodleap={isGoodleap}>{groupsThatHaveCheckboxes.length}</Val>
                                        </Fraction>
                                    ) : (
                                        <Fraction>
                                            <Val isGoodleap={isGoodleap}>0</Val>
                                            <Seperator isGoodleap={isGoodleap}>/</Seperator>
                                            <Val isGoodleap={isGoodleap}>0</Val>
                                        </Fraction>
                                    )}
                                </MetaItem>
                                : 
                                <></> 
                            }
                        </MetaContainer>
                    </Left>
                    <Right>
                        {givenConsent ? (
                            <ButtonWhite isGoodleap={isGoodleap}>
                                <ButtonIcon src={consentGiven} />
                                Consent Given
                            </ButtonWhite>
                        ) : (
                            <div style={{ position: 'relative' }}>
                                {!consentModalShown && (
                                    <img
                                        src={rightArrow}
                                        style={{
                                            width: scale * 45 + 'px',
                                            maxWidth: scale * 45 + 'px',
                                            position: 'absolute',
                                            left: -35 * scale + 'px',
                                            top: 0,
                                        }}
                                    />
                                )}
                                <ButtonSave onClick={openConsentFn}>
                                    <ButtonIcon src={consentNeeded} />
                                    Give Consent
                                </ButtonSave>
                            </div>
                        )}
                        {
                            // Consent has been given and signing is about to begin
                            givenConsent && clientActions && clientActions.filter(
                                a => a.type != 'FreeText' ? !a.clicked : a.meta && a.meta.required ? !a.clicked : false
                            ).length != 0 && (
                                <>
                                    <div style={{ position: 'relative' }}>
                                        {!initialModalShown && !signatureModalShown && scrollArrow && !startedSigning && (
                                            <img
                                                src={rightArrow}
                                                style={{
                                                    width: scale * 45 + 'px',
                                                    maxWidth: scale * 45 + 'px',
                                                    position: 'absolute',
                                                    left: -35 * scale + 'px',
                                                    top: 0,
                                                }}
                                            />
                                        )}
                                        <ButtonSave
                                            onClick={() => {
                                                autoScrollFn()
                                            }}
                                            isGoodleap={isGoodleap}
                                        >
                                            {!
                                                isGoodleap ?
                                                <ButtonIcon src={signingStart} />
                                                :
                                                <></>
                                            }
                                            {clientActions
                                                ? clientActions.filter(a => a.clicked).length == 0
                                                    ? 'Start signing'
                                                    : 'Next signature'
                                                : 'Loading'}
                                        </ButtonSave>
                                    </div>
                                </>
                            )
                        }
                        {
                            // Consent has been given and signing is complete
                            givenConsent && clientActions && clientActions.filter(
                                a => a.type != 'FreeText' ? !a.clicked : a.meta && a.meta.required ? !a.clicked : false
                            ).length == 0 && (
                                <>
                                    <ButtonWhite isGoodleap={isGoodleap}>
                                        {!
                                            isGoodleap ?
                                            <ButtonIcon src={signingComplete} />
                                            :
                                            <></>
                                        }
                                        Signing complete
                                    </ButtonWhite>

                                    <div style={{ position: 'relative', opacity: attemptingToSave ? 0 : 1 }}>
                                        {!initialModalShown && !signatureModalShown && (
                                            <img
                                                src={rightArrow}
                                                style={{
                                                    width: scale * 45 + 'px',
                                                    maxWidth: scale * 45 + 'px',
                                                    position: 'absolute',
                                                    left: -35 * scale + 'px',
                                                    top: 0,
                                                }}
                                            />
                                        )}
                                        <ButtonSave
                                            onClick={() => {
                                                if (!attemptingToSave) {
                                                    saveFn()
                                                }
                                            }}
                                            style={{ width: '100px' }}
                                        >
                                            {!
                                                isGoodleap ?
                                                <ButtonIcon src={saveIcon} />
                                                : <></>
                                            }
                                            Save
                                        </ButtonSave>
                                    </div>
                                </>
                            )
                        }
                    </Right>
                </Container>
            </Wrapper>
        </>
    )
}
