import axios from 'axios'
import dummyDouble from '../dummyDouble.json'
const server =
    process.env.REACT_APP_IS_DEPLOYED && process.env.REACT_APP_IS_DEPLOYED == 'whooRah'
        ? 'https://integrations.sunobiapp.com'
        : 'http://10.0.9.30:9090'

const metaTranslate = ({ fields, encode }) => {
    if (encode)
        return fields.map(f => ({
            ...f,
            meta: Object.keys(f.meta).length ? JSON.stringify(f.meta) : '',
        }))
    // Decode
    return fields.map(f => ({
        ...f,
        meta: f.meta.length ? JSON.parse(f.meta) : {},
    }))
}

const get = async body => {
    const url = `${server}/documents/hia?proposalRecordID=${body.proposalRecordID}&hiaSuffix=${body.hiaSuffix}&dateCreated=${body.dateCreated}`
    const response = await axios.get(url)
    const dataToReturn = {
        ...response.data,
        Fields: metaTranslate({ fields: response.data.Fields, encode: false }),
    }
    //console.log(dataToReturn)
    return dataToReturn
}

const put = async body => {
    const encodedFields = metaTranslate({ fields: body.fields, encode: true })
    const translatedBody = {
        ...body,
        fields: encodedFields,
    }

    const url = `${server}/documents/hia`
    const response = await axios.put(url, translatedBody)

    const { data } = response
    return {
        ...data,
        fields: metaTranslate({ fields: data.fields, encode: false }),
    }
}

const post = async body => {
    const url = `${server}/documents/hia`
    try {
        const response = await axios.post(url, body)
        return response.data
    } catch (err) {
        const { response } = err
        return response.data
    }
}

const getMultiple = async body => {
    //return dummyDouble
    // http://localhost:3000/4728/multiple/hia,nem/sign/1616751229000 Has a HIA and has fields in both!

    // hia-dev.sunobiapp.com/4771/nem/734/sign/1616811420000
    // https://integrations.sunobiapp.com/documents/multiple?proposalRecordID=4754&types=hia,nem&dateCreated=1616797867000

    const url = `${server}/documents/multiple?proposalRecordID=${body.proposalRecordID}&types=${body.types}&dateCreated=${body.dateCreated}`
    const response = await axios.get(url)
    const { data } = response
    const translatedDocuments = []
    data.Documents.forEach(d => {
        translatedDocuments.push({
            ...d,
            Fields: metaTranslate({ fields: d.Fields, encode: false }),
        })
    })
    return {
        ...response.data,
        Documents: translatedDocuments,
    }
}

const postMultiple = async body => {
    const url = `${server}/documents/multiple`
    try {
        const response = await axios.post(url, body)
        return response.data
    } catch (err) {
        const { response } = err
        return response.data
    }
}

export { get, put, post, getMultiple, postMultiple }
