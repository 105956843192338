import React, { Component } from 'react' // eslint-disable-line
import tw, { styled } from 'twin.macro'
import SignatureCanvas from 'react-signature-canvas'

const ModalWrapper = tw.div`
    flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center
`
const ModalBackgroundWrapper = tw.div`
    fixed inset-0 transition-opacity
`
const ModalBackground = tw.div`
    absolute inset-0 bg-gray-500 opacity-75
`
const ModalBlock = tw.div`
    inline-block bg-white rounded-lg 
    px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all
`
const Container = tw.div``
const Title = tw.h3`

    text-sm text-gray-500 mt-2 ml-1
    font-light
    uppercase
    tracking-wider
`
const ButtonContainer = tw.div`
    mt-2 grid grid-cols-2 gap-3 grid-flow-row-dense
`
const Button = tw.button`
    w-full inline-flex justify-center rounded-md border border-transparent shadow-sm 
    py-2
    text-white
    bg-blue-600 hover:bg-blue-700 focus:outline-none 
    col-start-2
    text-xs font-light
    uppercase
    tracking-wider
`
const ButtonCancel = tw.button`
    mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 bg-white 
    text-gray-700 
    hover:bg-gray-50 focus:outline-none 
    mt-0 col-start-1
    text-xs font-light
    uppercase
    tracking-wider
`

class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataURL: null,
        }
        this.sigPad = {}
    }

    // CallBack when user clicks the right hand button
    confirm = () => {
        const dataURL = this.state.dataURL ? this.state.dataURL : this.sigPad.getCanvas().toDataURL('image/png')
        this.setState({ dataURL }, () => {
            this.props.updateImage({ type: this.props.type, data: this.state.dataURL })
            this.props.hide()
        })
    }

    // CallBack when user clicks the left hand button
    cancel = () => {
        if (this.state.dataURL) {
            this.setState({ dataURL: null })
        } else {
            this.props.hide()
        }
    }

    render() {
        const { type, show, hideFn, showName, name } = this.props

        // Show is inherited from the parent
        const Wrapper = styled.div(() => [tw`fixed z-10 inset-0 overflow-y-auto`, !show && tw`hidden`])

        return (
            <Wrapper>
                <ModalWrapper>
                    <ModalBackgroundWrapper>
                        <ModalBackground />
                    </ModalBackgroundWrapper>
                    <ModalBlock role="dialog" style={{ marginTop: '15vh' }}>
                        <Container>
                            {showName ? <Title style={{ marginTop: -5, marginBottom: 10 }}>{name}</Title> : <></>}
                            {this.state.dataURL ? (
                                <img
                                    src={this.state.dataURL}
                                    style={{
                                        width: type.includes('initial') ? 322 : 380,
                                        height: type.includes('initial') ? 252 : 280,
                                    }}
                                />
                            ) : (
                                <SignatureCanvas
                                    ref={ref => {
                                        this.sigPad = ref
                                    }}
                                    penColor="rgba(0,0,0,1)"
                                    minWidth={1}
                                    canvasProps={{
                                        width: type.includes('initial') ? 322 : 380,
                                        height: type.includes('initial') ? 252 : 280,
                                        className: 'signature-canvas',
                                    }}
                                />
                            )}
                            {!this.state.dataURL ? (
                                <Title>Draw your {type.includes('initial') ? 'initials' : 'signature'}</Title>
                            ) : (
                                <></>
                            )}
                        </Container>

                        <ButtonContainer>
                            <ButtonCancel type="button" onClick={this.cancel}>
                                {this.state.dataURL ? 'Reset' : 'Cancel'}
                            </ButtonCancel>
                            <Button type="button" className="" onClick={this.confirm}>
                                Confirm
                            </Button>
                        </ButtonContainer>
                    </ModalBlock>
                </ModalWrapper>
            </Wrapper>
        )
    }
}

export default Modal
