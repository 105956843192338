import React from 'react' // eslint-disable-line
import tw, { styled } from 'twin.macro'

const Container = tw.div`
    flex
`

const Title = tw.h3`
    capitalize
    text-sm font-medium 
    text-gray-800
`
const Message = tw.div`
    mt-2 text-sm 
    text-gray-800
`

export default ({ status, message }) => {
    const Wrapper = styled.div(() => [
        tw`fixed rounded-md bg-green-300 p-4
        bg-opacity-80
        shadow-sm`,
        status == 'bad' && tw`bg-red-300 bg-opacity-80`,
        status == 'uploading' && tw`bg-yellow-300 bg-opacity-80`,
    ])
    return (
        <Wrapper style={{ top: '5px', right: '5px', zIndex: 9999 }}>
            <Container>
                <div>
                    {status != 'bad' ? <Title>{status}</Title> : <></>}
                    <Message>
                        <p>{message}</p>
                    </Message>
                </div>
            </Container>
        </Wrapper>
    )
}
