import React from 'react' // eslint-disable-line
import tw from 'twin.macro'

export default () => (
    <div
        style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#222',
        }}
    >
        <div>
            <h1 style={{ fontSize: '2em', marginBottom: '10px', color: 'rgba(256,256,256,0.7)' }}>
                Your Proposal Embed Link has been misconfigured
            </h1>
            <pre style={{ color: 'rgba(256,256,256,0.7)' }}>
                Please ensure it follows this pattern
                documents.sunobiapp.com/proposalRecordID/urlPrefix/Mode/unixDateCreated
            </pre>
        </div>
    </div>
)
