import React, { Component } from 'react' // eslint-disable-line
import tw, { styled } from 'twin.macro'
import rightArrow from '../icons/BYA-v2.gif'
import consentCheckbox from '../icons/consentCheckbox.svg'
import consentGiven from '../icons/consentGiven.svg'

const ModalWrapper = tw.div`
    flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center
`
const ModalBackgroundWrapper = tw.div`
    fixed inset-0 transition-opacity
`
const ModalBackground = tw.div`
    absolute inset-0 bg-gray-500 opacity-75
`
const ModalBlock = styled.div`
    ${tw`inline-block bg-white rounded-lg 
    p-10 text-left overflow-hidden shadow-xl transform transition-all`}
    position: absolute;
    top: 50%;
    margin-top: -120px;
`
const Container = tw.div``
const Title = styled.h3`
    ${tw`text-base font-normal uppercase tracking-wider text-left`}
    color: #222;
    font-size: 20px;
`
const ButtonContainer = tw.div`
    flex justify-between
`

const ButtonIcon = styled.img`
    margin-right: 7px;
`
const Button = styled.button`
    ${tw`inline-flex justify-center items-center shadow-sm  text-white focus:outline-none font-normal uppercase tracking-wider`}
    font-size: 12px;
    width: 170px;
    height: 38px;
    border-radius: 30px;
    background-color: #7fba23;
`
const ButtonDisabled = styled(Button)`
    background-color: #f0f0f0;
`

const Description = tw.div`
    flex
    items-center
    justify-between
    pt-3
    pb-5
`
const Checkbox = tw.input`
    focus:ring-green-500 h-6 w-6 text-green-600 border-gray-300 rounded-md
    ml-2 mr-4
    
`
const P = styled.p`
    ${tw`font-light tracking-wider mb-1 text-justify`}
    font-size: 13px;
`

const BYA = styled.img`
    position: absolute;
`

const ConsentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90px;
`
const ConsentP = styled.p`
    color: #222;
    opacity: 0.5;
    font-size: 9px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 0;
`

class ConsentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            accept: false,
        }
    }

    cancel = () => {
        this.setState({ accept: false })
        this.props.hide()
    }
    render() {
        const { show } = this.props

        // Show is inherited from the parent
        const Wrapper = styled.div(() => [tw`fixed z-10 inset-0 overflow-y-auto`, !show && tw`hidden`])

        return (
            <Wrapper>
                <ModalWrapper>
                    <ModalBackgroundWrapper onClick={this.cancel}>
                        <ModalBackground />
                    </ModalBackgroundWrapper>
                    <ModalBlock role="dialog" style={{ maxWidth: '90vw', width: '500px', overflow: 'visible' }}>
                        <Container>
                            <Title>Do you give consent?</Title>
                            <Description>
                                <P>
                                    You agree to use an electronic document and an electronic signature. You understand
                                    that electronic signatures are legally binding. You agree to read the document and
                                    fill it out accurately and completely. Sunobi will record your IP address and your
                                    device details to validate your identification.
                                </P>
                            </Description>
                        </Container>

                        <ButtonContainer>
                            <div style={{ position: 'relative' }}>
                                {this.state.accept ? (
                                    <></>
                                ) : (
                                    <BYA
                                        src={rightArrow}
                                        style={{
                                            width: this.props.scale * 45 + 'px',
                                            maxWidth: this.props.scale * 45 + 'px',
                                            left: -48 * this.props.scale + 'px',
                                            top: -1 * this.props.scale + 'px',
                                        }}
                                    />
                                )}
                                <ConsentContainer>
                                    {!this.state.accept ? (
                                        <a
                                            onClick={() => {
                                                this.setState({ accept: true })
                                            }}
                                        >
                                            <img src={consentCheckbox} style={{ width: '34px', opacity: 0.5 }} />
                                        </a>
                                    ) : (
                                        <a
                                            onClick={() => {
                                                this.setState({ accept: false })
                                            }}
                                        >
                                            <img src={consentGiven} style={{ width: '43px' }} />
                                        </a>
                                    )}
                                    <ConsentP>Consent</ConsentP>
                                </ConsentContainer>
                            </div>
                            {this.state.accept ? (
                                <div style={{ position: 'relative' }}>
                                    {this.state.accept && (
                                        <img
                                            src={rightArrow}
                                            style={{
                                                width: this.props.scale * 45 + 'px',
                                                maxWidth: this.props.scale * 45 + 'px',
                                                position: 'absolute',
                                                left: -37 * this.props.scale + 'px',
                                                top: 0 * this.props.scale + 'px',
                                            }}
                                        />
                                    )}
                                    <Button type="button" onClick={this.props.confirm}>
                                        Confirm
                                    </Button>
                                </div>
                            ) : (
                                <ButtonDisabled type="button">Confirm</ButtonDisabled>
                            )}
                        </ButtonContainer>
                    </ModalBlock>
                </ModalWrapper>
            </Wrapper>
        )
    }
}

export default ConsentModal
