import React from 'react' // eslint-disable-line
import tw, { styled } from 'twin.macro'
import { Link } from 'react-router-dom'
const Wrapper = tw.div`
    fixed
    flex justify-center
`
const Container = tw.div`
    bg-gray-50
    flex items-center justify-between
    px-5
    py-2
    shadow-sm
    border-l
    border-b
    border-r
    border-gray-200

`
const Left = tw.div`
    flex-1 min-w-0
`
const Title = tw.h2`
    text-xl font-bold leading-7 text-gray-900
`
const MetaContainer = tw.div`
    flex flex-row flex-wrap justify-between
`
const MetaItem = tw.div`
    w-1/4
    flex items-center text-sm text-gray-400
    lowercase
`

const Right = styled.div`
    ${tw`flex flex-wrap items-center justify-end`}
    span {
        ${tw`w-1/3 flex justify-end mt-1`}
    }
`
    

const Button = tw.button`
    inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none
`
const Val = tw.span`
    text-gray-700
    font-medium
    ml-1

`

const ButtonSave = tw(Button)`text-white bg-green-400 hover:bg-green-600 border-none`

export default ({
    prefix,
    proposalRecordID,
    fieldCount,
    initialCount,
    signatureCount,
    shapeCount,
    groupsCount = 0,
    freeTextCount = 0,
    checkboxCount = 0,
    groupsFn,
    saveFn,
    localSaveFn,
    signModeRoute,
    password,
    updatePasswordFn,
    isV2,
    hasToken,
    authEmail, 
    showAuthModalFn,
    showMetaFn
}) => {
    const ButtonAuth = styled.div(() => [
        tw`inline-flex items-center px-2 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none cursor-pointer`,
        tw`text-white bg-red-400 hover:bg-red-600 border-none`,
        password && tw`bg-green-400 hover:bg-green-600`,
    ])
    return (
        <Wrapper style={{ width: '100vw', zIndex: 999 }}>
            <Container style={{ width: '934px' }}>
                <Left>
                    <Title>{prefix}</Title>
                    <MetaContainer>
                        <MetaItem>
                            Proposal<Val>{proposalRecordID}</Val>
                        </MetaItem>
                        <MetaItem>
                            Fields<Val>{fieldCount}</Val>
                        </MetaItem>
                        <MetaItem>
                            Initials<Val>{initialCount}</Val>
                        </MetaItem>
                        <MetaItem>
                            Signatures<Val>{signatureCount}</Val>
                        </MetaItem>
                        <MetaItem>
                            Shapes<Val>{shapeCount}</Val>
                        </MetaItem>
                        <MetaItem>
                            Groups<Val>{groupsCount}</Val>
                        </MetaItem>
                        <MetaItem>
                            Free Text<Val>{freeTextCount}</Val>
                        </MetaItem>
                        <MetaItem>
                            Checkbox<Val>{checkboxCount}</Val>
                        </MetaItem>
                    </MetaContainer>
                </Left>
                <Right>
                    <span>
                        <Button onClick={groupsFn} type="button">
                            Manage Groups
                        </Button>
                    </span>
                    {
                        !isV2 ? (
                            <>
                                <span>
                                    <ButtonAuth
                                        type="button"
                                        onClick={() => {
                                            const newPassword = prompt('Update Password for edit authenticaton', password)
                                            updatePasswordFn(newPassword)
                                        }}
                                    >
                                        Authenticate{password ? 'd' : ''}
                                    </ButtonAuth>
                                </span>
                                <span>
                                    <Button type="button" onClick={localSaveFn}>
                                        Dump Fields
                                    </Button>
                                </span>
                            </>
                        ) :

                        (
                            hasToken ?
                                    <span><ButtonSave onClick={showAuthModalFn}>{authEmail}</ButtonSave></span>
                             : <></>

                        )


                    }
                    

                    
                    {
                        isV2 ? <span><Button type="button" onClick={showMetaFn}>Edit Meta</Button></span> : <></>
                    }
                    

                    <span>
                        <ButtonSave onClick={saveFn} type="button">
                            Save {isV2? 'Contract': 'Fields'}
                        </ButtonSave>
                    </span>
                    <span>
                        <Link target={'_blank'} to={signModeRoute}>
                            <Button type="button">Open Sign</Button>
                        </Link>
                    </span>
                </Right>
            </Container>
        </Wrapper>
    )
}
