import React from 'react'
import tw from 'twin.macro'
import Tick from '../icons/tick.svg'
const Wrapper = tw.div`
    bg-gray-100
    w-screen
    h-screen
    flex
    items-center
    justify-center
    bg-gradient-to-b from-gray-100 to-gray-200
`
const Container = tw.div`
    flex items-center
    flex-col
    p-5
    md:p-10
    border
    rounded-lg
    shadow-md
    bg-white
    
`
const Title = tw.h1`
    text-lg
    md:text-2xl
    mb-3
    mt-5
    text-center
    font-light
    text-gray-500
`
const Paragraph = tw.p`
    text-center
    font-light
    text-xs
    md:text-sm
    text-gray-800

`
export default ({ loading = false }) => (
    <Wrapper>
        <Container style={{ width: '80vw', maxWidth: '600px' }}>
            <img src={Tick} style={{ width: '10%' }} />
            <Title>Thank you for signing your solar agreement.</Title>
            <Paragraph>
                A copy has be sent to you for your records. This completes the process. We look forward to helping you
                pay less for power!
            </Paragraph>
        </Container>
    </Wrapper>
)
