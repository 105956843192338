import React from 'react'
import { Page } from 'react-pdf/dist/esm/entry.webpack'
import { useInView } from 'react-intersection-observer'
const WrappedPage = ({ scale, pageNumber, originalHeight, originalWidth }) => {
    const { ref, inView } = useInView({
        threshold: 0.15,
    })

    return (
        <div ref={ref}>
            {inView ? (
                <Page
                    pageNumber={pageNumber}
                    scale={scale}
                    renderAnnotationLayer={false}
                    renderInteractiveForms={false}
                    renderTextLayer={false}
                />
            ) : (
                <div style={{ width: originalWidth * scale + 'px', height: originalHeight * scale + 'px' }} />
            )}
        </div>
    )
}

export default WrappedPage
