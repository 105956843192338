
import React from 'react' // eslint-disable-line
import tw, { styled } from 'twin.macro'


const Wrapper = styled.div`
    ${tw`
    fixed
    top-0
    left-0
    w-full
    h-full
    flex justify-center items-center
    `}
    pointer-events: none;
    p{
        animation: blinker 2.5s linear infinite;
        font-size: 10em;
        color: #fc9615;
        transform: rotate(-45deg);
    }
`


export default ({ children }) => (

    <Wrapper><p>Demo</p></Wrapper>
)
