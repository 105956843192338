import React, { Component } from 'react' // eslint-disable-line
import {
    ModalBlock,
    Container,
    ButtonContainer,
    Button,
    Label,
    HeaderLabel,
    Input,
    Select
} from './AdvancedModal.styles'

class AdvancedModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fontSize: '',
            maxWidth: '',
            prefix: '',
            postfix: '',
            color: '',
            decimals: '',
            signatureStyling: 0,
            secondaryType: '',
            defaultValue: '',
        }
    }
    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown)
        const { field } = this.props
        const { meta, top, left, value: defaultValue = "" } = field
        const {
            fontSize = '',
            maxWidth = '',
            prefix = '',
            postfix = '',
            color = '',
            decimals = '',
            signatureStyling = '',
            secondaryType = '',
            groupId = 0,
            required,
            hide,
        } = meta

        const requiredCastToString = typeof required == 'boolean' ? required ? "1" : "0": ""
        const hideCastToString = typeof hide == 'boolean' ? hide ? "1" : "0" : ""
        this.setState({
            top,
            left,
            fontSize,
            maxWidth,
            prefix,
            postfix,
            color,
            decimals,
            signatureStyling,
            secondaryType,
            groupId,
            hide: hideCastToString,
            required: requiredCastToString,
            defaultValue
        })
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown)
    }

    // for other devs who might not know keyCodes

    _handleKeyDown = event => {
        switch (event.key) {
            case 'ArrowRight':
                event.preventDefault()
                this.setState({ left: parseFloat(this.state.left) + 1 }, () => this.updateCoordinates('left'))
                break
            case 'ArrowLeft':
                event.preventDefault()
                this.setState({ left: parseFloat(this.state.left) - 1 }, () => this.updateCoordinates('left'))
                break
            case 'ArrowUp':
                event.preventDefault()
                this.setState({ top: parseFloat(this.state.top) - 1 }, () => this.updateCoordinates('top'))
                break
            case 'ArrowDown':
                event.preventDefault()
                this.setState({ top: parseFloat(this.state.top) + 1 }, () => this.updateCoordinates('top'))
                break
            default:
                console.log(event)
                console.log('Nothing')
                break
        }
    }

    updateParent = type => {
        // Get current value
        const originalValue = this.state[type]
        setTimeout(() => {
            // Value 200ms == previous value, user has stopped editing so we can update parent
            if (originalValue == this.state[type]) {
                if (type == 'secondaryType') {
                    this.props.updateFieldMetaFn({
                        secondaryType: this.state.secondaryType,
                    })
                } else{
                    this.props.updateFieldMetaFn({
                        fontSize: this.state.fontSize,
                        maxWidth: this.state.maxWidth,
                        prefix: this.state.prefix,
                        postfix: this.state.postfix,
                        color: this.state.color,
                        decimals: this.state.decimals,
                        signatureStyling: this.state.signatureStyling,
                        groupId: parseInt(this.state.groupId),
                        required: this.state.required, 
                        hide: this.state.hide,
                        defaultValue: this.state.defaultValue
                    })
                }
            }
        }, 200)
    }

    updateCoordinates = axis => {
        // Get current value
        const originalValue = this.state[axis]
        setTimeout(() => {
            if (originalValue == this.state[axis]) {
                this.props.updateCoordinatesFn({
                    axis,
                    value: this.state[axis],
                })
            }
        }, 200)
    }

    render() {
        const { hideComponent, hide, id, field, groups } = this.props
        console.log({ field })
        return (
            <ModalBlock
                role="dialog"
                style={{
                    minWidth: '150px',
                    top: '20px',
                    right: '20px',
                    position: 'fixed',
                    zIndex: 8888,
                }}
            >
                <div style={{ position: 'absolute', top: -10, right: -10 }}>
                    {hideComponent(() => {
                        hide()
                    })}
                </div>
                <Container>
                    {field.type != 'Signature' && field.type != 'Initial' && field.type != "FreeText" ? (
                        <HeaderLabel>
                            FID: <strong>{field.fid}</strong>, Page: <strong>{field.pageNumber}</strong>
                        </HeaderLabel>
                    ) : (
                        <HeaderLabel>
                            Page: <strong>{field.pageNumber}</strong>
                        </HeaderLabel>
                        
                    )}

                    { field.type == "Checkbox" ? 
                        <>  
                            <Label>Group. Must belong to a group</Label>
                            <Select value={this.state.groupId} onChange={e => {
                                this.setState({groupId: e.target.value}, () => this.updateParent('groupId'))
                            }}>
                                <option value="">Choose Group</option>
                                {
                                    groups.map(g => (
                                        <option value={g.id}>{g.label}</option>
                                    ))
                                }
                            </Select>
                            <Label>Hide Arrow when active?</Label>
                            <Select value={this.state.hide} onChange={e => {
                                this.setState({ hide: e.target.value }, () => this.updateParent('hide'))
                            }}>
                                <option value="">Show Arrow</option>
                                <option value="0">Show Arrow</option>
                                <option value="1">Hide Arrow</option>
                            </Select>
                        </>
                        : 
                        <></>
                    }
                    {field.type == "FreeText" ?
                        <>
                            <Label>Is this required</Label>
                            <Select value={this.state.required} onChange={e => {
                                this.setState({ required: e.target.value }, () => this.updateParent('required'))
                            }}>
                                <option value="">Choose</option>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </Select>
                        </>
                        :
                        <></>
                    }
                    {
                        field.type == "Checkbox"? 
                        <>
                                <Label>Default Value</Label>
                                <Select value={this.state.defaultValue} onChange={e => {
                                    this.setState({ defaultValue: e.target.value }, () => this.updateParent('defaultValue'))
                                }}>
                                    <option value="">Unticked</option>
                                    <option value="set">Ticked</option>
                                </Select>
                        </>
                        : 
                        <></>
                    }

                    <Label>Top Coordinates</Label>
                    <Input
                        type="text"
                        value={this.state.top}
                        onChange={e => this.setState({ top: e.target.value }, () => this.updateCoordinates('top'))}
                    />
                    <Label>Left Coordinate</Label>
                    <Input
                        type="text"
                        value={this.state.left}
                        onChange={e => this.setState({ left: e.target.value }, () => this.updateCoordinates('left'))}
                    />
                    {field.type != 'Signature' && field.type != 'Initial' ? (
                        <>
                            {field.type != "Checkbox" ?
                                <>
                                    <Label>Font Size (default = 9)</Label>
                                    <Input
                                        type="text"
                                        value={this.state.fontSize}
                                        onChange={e =>
                                            this.setState({ fontSize: e.target.value }, () => this.updateParent('fontSize'))
                                        }
                                    />
                                    <Label>Field Max Width</Label>
                                    <Input
                                        type="text"
                                        value={this.state.maxWidth}
                                        onChange={e =>
                                            this.setState({ maxWidth: e.target.value }, () => this.updateParent('maxWidth'))
                                        }
                                    />
                                </>
                                : <></>
                            }
                            {
                                field.type != "FreeText" && field.type != "Checkbox" ? (
                                    <>
                                        <Label>Prefix</Label>
                                        <Input
                                            type="text"
                                            value={this.state.prefix}
                                            onChange={e =>
                                                this.setState({ prefix: e.target.value }, () => this.updateParent('prefix'))
                                            }
                                        />
                                        <Label>Postfix</Label>
                                        <Input
                                            type="text"
                                            value={this.state.postfix}
                                            onChange={e =>
                                                this.setState({ postfix: e.target.value }, () => this.updateParent('postfix'))
                                            }
                                        />
                                </> )
                                : 
                                <></>
                            }
                            {field.type != "Checkbox" ?
                                <>
                                    <Label>
                                        Color - comma seperated rgbs
                                        <br />
                                        Example input: <strong>256,321,14</strong>
                                    </Label>
                                    <Input
                                        type="text"
                                        value={this.state.color}
                                        onChange={e =>
                                            this.setState({ color: e.target.value }, () => this.updateParent('color'))
                                        }
                                    />
                                    <Label>Font Family</Label>

                                    <Select
                                        value={this.state.signatureStyling}
                                        onChange={e =>
                                            this.setState({ signatureStyling: e.target.value }, () => {
                                                console.log({ e: e.target.value })
                                                this.updateParent('signatureStyling')
                                            })
                                        }
                                    >
                                        <option value="">Helvetica</option>
                                        <option value="lato">Lato</option>
                                        <option value="merriweather">Merriweather</option>
                                        <option value="montserrat">Montserrat</option>
                                        <option value="noto-sans">Noto Sans</option>
                                        <option value="nunito">Nunito</option>
                                        <option value="open-sans">Open Sans</option>
                                        <option value="poppins">Poppins</option>
                                        <option value="pt-sans">PT Sans</option>
                                        <option value="raleway">Raleway</option>
                                        <option value="roboto">Roboto</option>
                                        <option value="source-sans-pro">Source Sans Pro</option>

                                        <option value="">Below this are signature fonts</option>
                                        <option value="aguafina-script">Aguafina Script</option>
                                        <option value="dancing-script">Dancing Script</option>
                                        <option value="great-vibes">Great Vibes</option>
                                        <option value="italianno">Italianno</option>
                                        <option value="tangerine">Tangerine</option>
                                        <option value="yellowtail">Tellowtail</option>
                                    </Select>
                                </>
                                : 
                                <></>
                            }
                            {field.type != "FreeText" && field.type != "Checkbox" ? (
                                <>
                                <Select
                                    value={this.state.decimals}
                                    onChange={e =>
                                        this.setState({ decimals: e.target.value }, () => this.updateParent('decimals'))
                                    }
                                >
                                    <option value="">Choose Decimals</option>
                                    <option value="0">0</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="4">3</option>
                                    <option value="3">4</option>
                                </Select>
                                <Label>
                                    Must defined dp value for
                                    <br />
                                    comma number format
                                </Label>
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    ) : (
                        // This is initial or signature
                        <>
                            <Label>Primary or secondary</Label>
                            <Select
                                value={this.state.secondaryType}
                                onChange={e =>
                                    this.setState({ secondaryType: e.target.value }, () => {
                                        this.updateParent('secondaryType')
                                    })
                                }
                            >
                                <option value="">Primary</option>
                                <option value="secondary">Secondary</option>
                            </Select>
                        </>
                    )}
                </Container>
                <ButtonContainer>
                    <Button type="button" onClick={hide}>
                        Close
                    </Button>
                </ButtonContainer>
            </ModalBlock>
        )
    }
}

export default AdvancedModal
