import React from 'react'
import tw from 'twin.macro'
import loadingGif from '../icons/loading.gif'
const Wrapper = tw.div`
    bg-gray-100
    bg-opacity-80
    w-screen
    h-screen
    flex
    items-center
    justify-center
    
    fixed

`
const Container = tw.div`
    flex items-center
    flex-col
    p-5
    md:p-10
    border
    rounded-lg
    shadow-md
    bg-white
    
`
const Title = tw.h1`
    text-lg
    md:text-2xl
    mb-3
    mt-5
    text-center
    font-light
    text-gray-500
`
const Paragraph = tw.p`
    text-center
    font-light
    text-xs
    md:text-sm
    text-gray-800

`
export default () => (
    <Wrapper style={{ zIndex: 9999 }}>
        <Container style={{ width: '80vw', maxWidth: '600px' }}>
            <img src={loadingGif} style={{ width: '40%' }} />
            <Title>Thank you for signing your solar agreement.</Title>
            <Paragraph>Your agreement is currently being uploaded.</Paragraph>
        </Container>
    </Wrapper>
)
