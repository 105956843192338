import React, { Component } from 'react' // eslint-disable-line
import tw, { styled } from 'twin.macro'


const ModalBlock = tw.div`
    inline-block
    bg-gray-100 rounded-md 
    border-gray-200
    border-2
    px-4 pt-5 pb-4 text-left  shadow-md transform transition-all
`
const Container = styled.div`
    ${tw`flex flex-col`}
`
const Title = tw.h3`
    text-base font-normal text-gray-500 mt-2 ml-1
`
const ButtonContainer = tw.div`
    flex 
    flex-col
`
const Button = tw.button`
    w-full inline-flex justify-center rounded-md border border-transparent shadow-sm 
    py-2 px-2 text-base font-medium 
    text-white
    bg-green-600 hover:bg-green-700 focus:outline-none 
    text-xs
    mt-3 
`

const ButtonCancel = tw.button`
    w-full inline-flex justify-center rounded-md border border-transparent shadow-sm 
    py-2 px-2 text-base font-medium 
    text-white
    bg-red-600 hover:bg-red-700 focus:outline-none 
    text-xs
    mt-3 
`

const Label = tw.label`
    block text-sm font-medium text-gray-500 text-xs mt-2 pl-1
`

const Input = tw.input`
    mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-200 border-2 rounded-md py-1 px-2
`

class AuthModal extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { loginFn, email, password, updateStateFn, hideComponent, hide, deleteFn, hasToken } = this.props
        return (
            <ModalBlock
                role="dialog"
                style={{
                    minWidth: '150px',
                    top: '20px',
                    left: '50%',
                    marginLeft: '-75px',
                    position: 'fixed',
                    zIndex: 8888,
                }}
            >   
                { hasToken &&
                <div style={{ position: 'absolute', top: -10, right: -10 }}>
                    {hideComponent(() => {
                        hide()
                    })}
                </div>
                }
                <Container>
                <Title>Authenticate</Title>
                    <Label>Email</Label>
                    <Input
                        type="text"
                        value={email}
                        onChange={e => updateStateFn("authModalEmail", e.target.value)}
                    />
                    <Label>Password</Label>
                    <Input
                        type="password"
                        value={password}
                        onChange={e => updateStateFn("authModalPassword", e.target.value)}
                    />
                </Container>
                <ButtonContainer>
                    <Button type="button" onClick={loginFn}>
                        Login
                    </Button>
                </ButtonContainer>
                {
                    hasToken && (
                        <ButtonContainer>
                            <ButtonCancel type="button" onClick={deleteFn}>Remove Token</ButtonCancel>
                        </ButtonContainer>
                    )
                }
                
            </ModalBlock>
        )
    }
}

export default AuthModal
