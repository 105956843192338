import React from 'react' // eslint-disable-line
import tw from 'twin.macro'
import { withRouter } from 'react-router'
import { normalize, schema } from 'normalizr'

import { PDFDocument } from 'pdf-lib' // Used for modications to underlying PDF

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import Draggable, { DraggableCore } from 'react-draggable' // draggable lil divs
import { Helmet } from 'react-helmet'

import Header from './components/Header'
import Notification from './components/Notification'

import close from './icons/close.svg'
import advanced from './icons/advanced.svg'

import AdvancedModal from './components/AdvancedModal'
import GroupsModal from './components/GroupsModal'
import NewFieldModal from './components/NewFieldModal'
import consentGiven from './icons/consentGiven.svg'
import base64ToArrayBuffer from './utils/base64ToBuffer'
import stampWithData from './utils/stampWithData'

import { get, put } from './utils/network'

import {
    Wrapper,
    Container,
    DraggableField,
    DraggableToolbar,
    DraggableDelete,
    DraggableAdvanced,
} from './Edit.styles'

class Edit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageCount: null,
            pageNumber: 1,
            groups: [],
            fields: [],
            qb: [],
            initial: [],
            signature: [],
            freeText: [],
            checkbox: [],
            renderDrags: true,
            pdfCreated: false,
            password: '', // This will be a controlled input in the sidebar
            notification: null,
            hideAdvanced: false,
            groupsIsOpen: false,
        }
        this.fontSize = 9
        this.scale = 1.5
    }

    async componentDidMount() {
        // Get params from router path
        const { proposalRecordID, prefixA, prefixB, dateCreated } = this.props.match.params
        const hiaSuffix = prefixA + '/' + prefixB

        const data = await get({
            proposalRecordID,
            hiaSuffix,
            dateCreated,
        })
        this.setState(
            {
                fields: data.Fields,
                pageCount: data.PageCount,
                groups: data.Groups ? data.Groups: [],
                qb: data.Fields.filter(f => f.type == 'QB'),
                initial: data.Fields.filter(f => f.type == 'Initial'),
                signature: data.Fields.filter(f => f.type == 'Signature'),
                shape: data.Fields.filter(f => f.type == 'Shape'),
                freeText: data.Fields.filter(f => f.type == 'FreeText'),
                checkbox: data.Fields.filter(f => f.type == 'Checkbox'),
                proposalRecordID,
                prefixA,
                prefixB,
                hiaSuffix,
                dateCreated,
            },
            async () => {
                await this.renderPDF(data.DocumentData)
            },
        )
    }

    async renderPDF(rawDocBase64) {
        const arrayBuffer = base64ToArrayBuffer({ base64: rawDocBase64 })
        const pdfDoc = await PDFDocument.load(arrayBuffer)

        this.docBase64 = await pdfDoc.saveAsBase64({ dataUri: true })
        this.pdfDoc = pdfDoc

        this.setState({ pdfCreated: true })
    }

    onDocumentLoadSuccess = props => {
        console.log({ onDocumentLoadProps: props })
        const { numPages } = props
        const pageCount = numPages
        this.setState({ pageCount })
    }

    onPageOneLoadSuccess = ({ originalHeight, originalWidth }) => {
        this.setState({ originalWidth, originalHeight })
    }

    CreateField = async ({ type, fid }) => {
        console.log({ type, fid })

        let convertedType = ""
        switch (type) {
            case 'qb':
                convertedType = "QB"
                break
            case 'initial':
                convertedType = "Initial"
                break
            case 'signature':
                convertedType = "Signature"
                break
            case 'shape':
                convertedType = "Shape"
                break
            case 'freeText':
                convertedType = "FreeText"
                break
            case 'checkbox':
                convertedType = "Checkbox"
                break
            default:
                alert("No Type")
                return
        }

        const newObject = {
            // pseudo Hash/ID
            id: Math.floor(Math.random() * 200000000),
            top: Math.round(this.state.newFieldTop / this.scale),
            left: Math.round(this.state.newFieldLeft / this.scale),
            type: convertedType,
            pageNumber: this.state.newFieldPageNumber,
            meta: {},
        }

        const stateReset = {
            newFieldLeft: null,
            newFieldTop: null,
            newFieldPageNumber: null,
        }

        if (fid) {
            newObject.fid = parseInt(fid)
            const fields = [...this.state.qb, newObject, ...this.state.initial, ...this.state.signature]
            const { proposalRecordID } = this.props.match.params

            // Get updated field object
            const updated = await put({
                proposalRecordID: parseInt(proposalRecordID),
                clientAuthentication: this.state.password,
                fields: fields,
                hiaSuffix: this.state.hiaSuffix,
            }).catch(err => {
                this.setNotification({
                    status: 'bad',
                    message: `Data not saved.${'\n'}Please ensure your password is correct`,
                })
            })

            if (updated && updated.Status == 200 && !updated.HasError) {
                this.setNotification({ status: 'saved', message: 'Your data was saved' })
            }

            this.setState({
                qb: updated.fields.filter(f => f.type == 'QB'),
                ...stateReset,
            })
        } else {
            this.setState({
                [type]: [...this.state[type], newObject],
                ...stateReset,
            })
        }
    }

    MoveFieldAfterDrag = ({ lastX, lastY, type, id }) => {
        const fieldMoved = this.state[type].find(f => f.id == id)
        const oldFields = this.state[type].filter(f => f.id != id)

        fieldMoved.left += Math.round(lastX / this.scale)
        fieldMoved.top += Math.round(lastY / this.scale)

        this.setState(
            {
                renderDrags: false,
                hideAdvanced: true, // Forces the advanced modal to re-render if the field has been dragged while it has been open
            },
            () => {
                this.setState({
                    [type]: [...oldFields, fieldMoved],
                    renderDrags: true,
                    hideAdvanced: false,
                })
            },
        )
    }

    UpdateCoordinates = ({ axis, value }) => {
        const { advancedModalID, advancedModalType } = this.state

        const fieldToUpdate = { ...this.state[advancedModalType].find(f => f.id == advancedModalID) }
        const otherFields = this.state[advancedModalType].filter(f => f.id != advancedModalID)

        fieldToUpdate[axis] = value ? parseFloat(value) : 0

        this.setState(
            {
                renderDrags: false,
            },
            () => {
                this.setState({
                    [advancedModalType]: [...otherFields, fieldToUpdate],
                    renderDrags: true,
                })
            },
        )
    }

    UpdateFieldMeta = meta => {
        // Add the meta to the field that is currently the currently active
        const { advancedModalID, advancedModalType } = this.state

        const fieldToUpdate = { ...this.state[advancedModalType].find(f => f.id == advancedModalID) }
        const otherFields = this.state[advancedModalType].filter(f => f.id != advancedModalID)

        // make this better when time
        const cleanedMeta = {}

        if (meta.fontSize) {
            cleanedMeta.fontSize = parseInt(meta.fontSize)
        }
        if (meta.maxWidth) {
            cleanedMeta.maxWidth = parseInt(meta.maxWidth)
        }
        if (meta.prefix) {
            cleanedMeta.prefix = meta.prefix
        }
        if (meta.postfix) {
            cleanedMeta.postfix = meta.postfix
        }
        if (meta.color) {
            cleanedMeta.color = meta.color
        }
        if (meta.decimals) {
            cleanedMeta.decimals = parseInt(meta.decimals)
        }

        if (meta.signatureStyling) {
            cleanedMeta.signatureStyling = meta.signatureStyling
        }

        if (meta.secondaryType) {
            cleanedMeta.secondaryType = meta.secondaryType
        }

        if (meta.groupId) {
            cleanedMeta.groupId = meta.groupId
        }
        
        if (typeof meta.required != "undefined") {
            if(meta.required == "1" || meta.required == "0"){
                cleanedMeta.required = meta.required == "1" ? true : false    
            }
        }

        if (typeof meta.hide != "undefined") {
            if(meta.hide == "1" || meta.hide == "0"){
                cleanedMeta.hide = meta.hide == "1" ? true : false    
            }
        }
        
        // This is a hack, the advanced modal sends the value 
        if (typeof meta.defaultValue === 'string' && fieldToUpdate.type == 'Checkbox'){
            fieldToUpdate.value = meta.defaultValue
        }

        fieldToUpdate.meta = cleanedMeta

        console.log({ fieldToUpdate })
        this.setState(
            {
                renderDrags: false,
            },
            () => {
                this.setState({
                    [advancedModalType]: [...otherFields, fieldToUpdate],
                    renderDrags: true,
                })
            },
        )
    }

    DeleteField = ({ type, id }) => {
        // If you're deleting the field you're editing, disable modal first
        if (id == this.state.advancedModalID) {
            this.setState({
                advancedModalID: null,
                advancedModalType: null,
                [type]: [...this.state[type].filter(f => f.id != id)],
            })
        } else {
            this.setState({
                [type]: [...this.state[type].filter(f => f.id != id)],
            })
        }
    }

    setNotification = ({ status, message }) => {
        this.setState(
            {
                notification: {
                    status,
                    message,
                },
            },
            () => {
                setTimeout(() => {
                    this.setState({ notification: null })
                }, 1500)
            },
        )
    }

    saveDataToServer = async () => {
        const fields = [...this.state.qb, ...this.state.initial, ...this.state.signature, ...this.state.freeText, ...this.state.checkbox]

        const updates = await put({
            proposalRecordID: parseInt(this.state.proposalRecordID),
            clientAuthentication: this.state.password,
            fields,
            groups: this.state.groups,
            hiaSuffix: this.state.hiaSuffix,
        }).catch(err => {
            this.setNotification({
                status: 'bad',
                message: `Data not saved.${'\n'}Please ensure your password is correct`,
            })
        })

        if (updates && updates.Status == 200 && !updates.HasError) {
            this.setNotification({ status: 'saved', message: 'Your data has been saved' })
        }

        /* 
            Code related to saving JSON blob locally during dev 
            No longe needed
            
        */
    }

    saveFieldsToLocalFile = () => {
        const fields = [...this.state.qb, ...this.state.initial, ...this.state.signature]
        const fileData = JSON.stringify(fields, null, 2)
        const blob = new Blob([fileData], { type: 'text/plain' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = this.state.hiaSuffix + '_fields.json'
        link.href = url
        link.click()
    }

    capitalize = s => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    advancedClickFn =(id, type) => {
        this.setState(
            {
                advancedModalID: null,
                advancedModalType: null,
            },
            () =>
                this.setState({
                    advancedModalID: id,
                    advancedModalType: type,
                }),
        )
    }

    render() {
        const viewerStyles = {
            overflowY: 'scroll',
            display: 'flex',
            justifyContent: 'center',
            borderTop: '1px solid rgba(0,0,0,0.2)',
            borderLeft: '1px solid rgba(0,0,0,0.2)',
            marginTop: '63px',
        }

        console.log({ state: this.state })

        return (
            <>
                <Helmet>
                    <title>
                        {this.state.prefixA
                            ? this.capitalize(this.state.prefixA) +
                            ' ' +
                            this.capitalize(this.state.prefixB) +
                            ' Document Editing'
                            : 'Sunobi Document Editing'}
                    </title>
                </Helmet>
                {this.state.notification ? (
                    <Notification status={this.state.notification.status} message={this.state.notification.message} />
                ) : (
                    <></>
                )}
                {this.state.pdfCreated ? (
                    <Header
                        prefix={this.state.hiaSuffix}
                        proposalRecordID={this.props.match.params.proposalRecordID}
                        fieldCount={this.state.qb.length}
                        initialCount={this.state.initial.length}
                        signatureCount={this.state.signature.length}
                        shapeCount={this.state.shape.length}
                        groupsCount={this.state.groups.length}
                        freeTextCount={this.state.freeText.length}
                        checkboxCount={this.state.checkbox.length}

                        saveFn={this.saveDataToServer}
                        localSaveFn={this.saveFieldsToLocalFile}
                        signModeRoute={`/${this.state.proposalRecordID}/${this.state.hiaSuffix}/sign/${this.state.dateCreated}`}
                        password={this.state.password}
                        updatePasswordFn={password => this.setState({ password })}
                        groupsFn={() => this.setState({ groupsIsOpen: !this.state.groupsIsOpen })}
                    />
                ) : (
                    <></>
                )}
                <Wrapper>
                    <Container>
                        {this.state.pdfCreated ? (
                            <div style={viewerStyles}>
                                <Document file={this.docBase64} onLoadSuccess={this.onDocumentLoadSuccess}>
                                    {[...Array(this.state.pageCount)].map((_, pageIndex) => (
                                        <React.Fragment key={pageIndex + '-fragment'}>
                                            <div style={{ position: 'relative' }} key={pageIndex + '-div'}>
                                                <Page
                                                    pageNumber={pageIndex + 1}
                                                    scale={1.5}
                                                    key={pageIndex + '-page'}
                                                    onRenderSuccess={props => {
                                                        if (pageIndex == 0) this.onPageOneLoadSuccess(props)
                                                    }}
                                                    // False
                                                    renderAnnotationLayer={false}
                                                    renderInteractiveForms={false}
                                                    renderTextLayer={false}
                                                />
                                                {this.state.originalWidth && this.state.originalHeight ? (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                        onContextMenu={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                newFieldLeft: e.nativeEvent.layerX,
                                                                newFieldTop: e.nativeEvent.layerY,
                                                                newFieldPageNumber: pageIndex + 1,
                                                            })
                                                        }}
                                                    >
                                                        {
                                                            // Actual Fields
                                                            this.state.qb
                                                                .filter(f => f.pageNumber == pageIndex + 1)
                                                                .map(f => {
                                                                    if (!this.state.renderDrags) return <></>
                                                                    return (
                                                                        <Draggable
                                                                            key={`${f.id}`}
                                                                            bounds="parent"
                                                                            axis="both"
                                                                            handle={`#handle-${f.id}`}
                                                                            scale={1}
                                                                            //grid={[1, 1]}
                                                                            onStop={(_, d) => {
                                                                                this.MoveFieldAfterDrag({
                                                                                    lastX: d.lastX,
                                                                                    lastY: d.lastY,
                                                                                    type: 'qb',
                                                                                    id: f.id,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DraggableField
                                                                                className="draggable"
                                                                                id={`handle-${f.id}`}
                                                                                key={`handle-${f.id}`}
                                                                                style={{
                                                                                    left:
                                                                                        Math.round(
                                                                                            f.left * this.scale,
                                                                                        ) + 'px',
                                                                                    top:
                                                                                        Math.round(f.top * this.scale) +
                                                                                        'px',
                                                                                    minHeight: '45px',
                                                                                }}
                                                                            >
                                                                                <DraggableToolbar
                                                                                    style={{ minWidth: '50px' }}
                                                                                    key={`toolbar-${f.id}`}
                                                                                >
                                                                                    <p>{f.fid}</p>
                                                                                    <div
                                                                                        style={{
                                                                                            marginTop: '-14px',
                                                                                            display: 'flex',
                                                                                        }}
                                                                                    >
                                                                                        <DraggableAdvanced
                                                                                            style={{
                                                                                                width: '18px',
                                                                                                height: '18px',
                                                                                                marginRight: '2px',
                                                                                            }}
                                                                                            onMouseDown={e => {
                                                                                                e.stopPropagation()
                                                                                            }}
                                                                                            onClick={() => this.advancedClickFn(f.id, 'qb')}
                                                                                        >
                                                                                            <img
                                                                                                src={advanced}
                                                                                                style={{
                                                                                                    width: '10px',
                                                                                                }}
                                                                                            />
                                                                                        </DraggableAdvanced>
                                                                                        <DraggableDelete
                                                                                            style={{
                                                                                                width: '18px',
                                                                                                height: '18px',
                                                                                                marginRight: '-14px',
                                                                                            }}
                                                                                            onMouseDown={e => {
                                                                                                e.stopPropagation()
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                this.DeleteField({
                                                                                                    type: 'qb',
                                                                                                    id: f.id,
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src={close}
                                                                                                style={{ width: '6px' }}
                                                                                            />
                                                                                        </DraggableDelete>
                                                                                    </div>
                                                                                </DraggableToolbar>
                                                                                <p
                                                                                    style={{
                                                                                        fontSize:
                                                                                            (f.meta.fontSize
                                                                                                ? f.meta.fontSize
                                                                                                : this.fontSize) *
                                                                                            this.scale +
                                                                                            'px',
                                                                                        width: f.meta.maxWidth
                                                                                            ? f.meta.maxWidth *
                                                                                            this.scale +
                                                                                            'px'
                                                                                            : 'auto',
                                                                                        color: f.meta.color
                                                                                            ? 'rgb(' +
                                                                                            f.meta.color +
                                                                                            ')'
                                                                                            : 'initial',
                                                                                    }}
                                                                                >
                                                                                    {f.meta.prefix && f.value
                                                                                        ? f.meta.prefix
                                                                                        : ''}
                                                                                    {!f.value
                                                                                        ? ''
                                                                                        : typeof f.meta.decimals ===
                                                                                            'undefined'
                                                                                            ? f.value
                                                                                            : parseFloat(
                                                                                                f.value,
                                                                                            ).toLocaleString(undefined, {
                                                                                                minimumFractionDigits:
                                                                                                    f.meta.decimals,
                                                                                                maximumFractionDigits:
                                                                                                    f.meta.decimals,
                                                                                            })}
                                                                                    {f.meta.postfix && f.value
                                                                                        ? f.meta.postfix
                                                                                        : ''}
                                                                                </p>
                                                                            </DraggableField>
                                                                        </Draggable>
                                                                    )
                                                                }
                                                                    
                                                                )
                                                        }
                                                        {
                                                            // Initial Placement
                                                            this.state.initial
                                                                .filter(f => f.pageNumber == pageIndex + 1)
                                                                .map(f => {
                                                                    if(!this.state.renderDrags) return <></>
                                                                    return (
                                                                        <Draggable
                                                                            key={`${f.id}`}
                                                                            bounds="parent"
                                                                            axis="both"
                                                                            handle={`#handle-${f.id}`}
                                                                            scale={1}
                                                                            onStop={(_, d) => {
                                                                                this.MoveFieldAfterDrag({
                                                                                    lastX: d.lastX,
                                                                                    lastY: d.lastY,
                                                                                    type: 'initial',
                                                                                    id: f.id,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DraggableField
                                                                                className="draggable"
                                                                                id={`handle-${f.id}`}
                                                                                style={{
                                                                                    left:
                                                                                        Math.round(
                                                                                            f.left * this.scale,
                                                                                        ) + 'px',
                                                                                    top:
                                                                                        Math.round(f.top * this.scale) +
                                                                                        'px',
                                                                                    height: '60px',
                                                                                    width: '77px',
                                                                                }}
                                                                            >
                                                                                <DraggableToolbar>
                                                                                    <p>
                                                                                        {f.meta &&
                                                                                            f.meta.secondaryType ==
                                                                                            'secondary'
                                                                                            ? 'Co-'
                                                                                            : ''}
                                                                                        Initial
                                                                                    </p>
                                                                                    <DraggableAdvanced
                                                                                        style={{
                                                                                            width: '18px',
                                                                                            height: '18px',
                                                                                            position: 'absolute',
                                                                                            right: '14px',
                                                                                            marginTop: '-14px',
                                                                                        }}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() => this.advancedClickFn(f.id, 'initial')}
                                                                                    >
                                                                                        <img
                                                                                            src={advanced}
                                                                                            style={{
                                                                                                width: '10px',
                                                                                            }}
                                                                                        />
                                                                                    </DraggableAdvanced>
                                                                                    <DraggableDelete
                                                                                        style={{
                                                                                            padding: '6px',
                                                                                            marginTop: '-14px',
                                                                                            marginRight: '-14px',
                                                                                        }}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.DeleteField({
                                                                                                type: 'initial',
                                                                                                id: f.id,
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={close}
                                                                                            style={{ width: '6px' }}
                                                                                        />
                                                                                    </DraggableDelete>
                                                                                </DraggableToolbar>
                                                                            </DraggableField>
                                                                        </Draggable>
                                                                    )
                                                                })
                                                        }
                                                        {
                                                            // Signature Placement
                                                            this.state.signature
                                                                .filter(f => f.pageNumber == pageIndex + 1)
                                                                .map(f =>
                                                                    this.state.renderDrags ? (
                                                                        <Draggable
                                                                            key={`${f.id}`}
                                                                            bounds="parent"
                                                                            axis="both"
                                                                            handle={`#handle-${f.id}`}
                                                                            scale={1}
                                                                            onStop={(_, d) => {
                                                                                this.MoveFieldAfterDrag({
                                                                                    lastX: d.lastX,
                                                                                    lastY: d.lastY,
                                                                                    type: 'signature',
                                                                                    id: f.id,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DraggableField
                                                                                className="draggable"
                                                                                id={`handle-${f.id}`}
                                                                                style={{
                                                                                    left:
                                                                                        Math.round(
                                                                                            f.left * this.scale,
                                                                                        ) + 'px',
                                                                                    top:
                                                                                        Math.round(f.top * this.scale) +
                                                                                        'px',
                                                                                    height: '80px',
                                                                                    width: '109px',
                                                                                }}
                                                                            >
                                                                                <DraggableToolbar>
                                                                                    <p>
                                                                                        {f.meta &&
                                                                                            f.meta.secondaryType ==
                                                                                            'secondary'
                                                                                            ? 'Co '
                                                                                            : ''}
                                                                                        Signature
                                                                                    </p>
                                                                                    <DraggableAdvanced
                                                                                        style={{
                                                                                            width: '18px',
                                                                                            height: '18px',
                                                                                            position: 'absolute',
                                                                                            right: '28px',
                                                                                        }}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() => this.advancedClickFn(f.id, 'signature')}
                                                                                    >
                                                                                        <img
                                                                                            src={advanced}
                                                                                            style={{
                                                                                                width: '10px',
                                                                                            }}
                                                                                        />
                                                                                    </DraggableAdvanced>
                                                                                    <DraggableDelete
                                                                                        style={{ padding: '6px' }}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.DeleteField({
                                                                                                type: 'signature',
                                                                                                id: f.id,
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={close}
                                                                                            style={{ width: '6px' }}
                                                                                        />
                                                                                    </DraggableDelete>
                                                                                </DraggableToolbar>
                                                                            </DraggableField>
                                                                        </Draggable>
                                                                    ) : (
                                                                        <></>
                                                                    ),
                                                                )
                                                        }

                                                        {
                                                            // FreeText Placement
                                                            this.state.freeText
                                                                .filter(f => f.pageNumber == pageIndex + 1)
                                                                .map(f => {
                                                                    console.log({freeTextField: f})
                                                                    if (!this.state.renderDrags) return <></>
                                                                    const draggableStyles = {
                                                                        left:
                                                                            Math.round(
                                                                                f.left * this.scale,
                                                                            ) + 'px',
                                                                        top:
                                                                            Math.round(f.top * this.scale) +
                                                                            'px',
                                                                        height: '80px',
                                                                    }
                                                                    const pretendInputStyles = {
                                                                        width: 100 * this.scale + 'px',
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        left: 0,
                                                                        borderColor: 'black',
                                                                        borderWidth: '1px',
                                                                        borderRadius: '3px',
                                                                        padding: '2px 5px'
                                                                    }


                                                                    const advancedStyles = {
                                                                        width: '18px',
                                                                        height: '18px',
                                                                        position: 'absolute',
                                                                        right: '22px',
                                                                        bottom: '0px'
                                                                    }

                                                                    if(f.meta){
                                                                        if(f.meta.maxWidth){
                                                                            pretendInputStyles.width = f.meta.maxWidth * this.scale + 'px'
                                                                        }
                                                                        if(f.meta.fontSize){
                                                                            pretendInputStyles.fontSize = f.meta.fontSize * this.scale + 'px'
                                                                        }
                                                                        if(f.meta.color){
                                                                            pretendInputStyles.color = 'rgb(' + f.meta.color + ')'
                                                                        }
                                                                    }

                                                                    return (
                                                                        <Draggable
                                                                            key={`${f.id}`}
                                                                            bounds="parent"
                                                                            axis="both"
                                                                            handle={`#handle-${f.id}`}
                                                                            scale={1}
                                                                            onStop={(_, d) => {
                                                                                this.MoveFieldAfterDrag({
                                                                                    lastX: d.lastX,
                                                                                    lastY: d.lastY,
                                                                                    type: 'freeText',
                                                                                    id: f.id,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DraggableField
                                                                                className="draggable"
                                                                                id={`handle-${f.id}`}
                                                                                style={draggableStyles}
                                                                            >
                                                                                <input style={pretendInputStyles} defaultValue="sample text"/>
                                                                                <p style={{marginTop: "30px"}}>
                                                                                    {f.meta && f.meta.required ? `Required` : 'Optional'}
                                                                                </p>
                                                                                <DraggableToolbar style={{position: 'absolute', bottom: "-10px", right: "-10px"}}>
                                                                                    <DraggableAdvanced
                                                                                        style={advancedStyles}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() => this.advancedClickFn(f.id, 'freeText')}
                                                                                    >
                                                                                        <img src={advanced} style={{width: '10px'}} />
                                                                                    </DraggableAdvanced>
                                                                                    <DraggableDelete
                                                                                        style={{ padding: '6px' }}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.DeleteField({
                                                                                                type: 'freeText',
                                                                                                id: f.id,
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <img src={close} style={{ width: '6px' }} />
                                                                                    </DraggableDelete>
                                                                                </DraggableToolbar>
                                                                            </DraggableField>
                                                                        </Draggable>
                                                                    )
                                                                })
                                                        }

                                                        {
                                                            // checkbox
                                                            this.state.checkbox
                                                            .filter(f => f.pageNumber == pageIndex + 1)
                                                            .map(f => {

                                                                
                                                                if (!this.state.renderDrags) return <></>
                                                                const relatedGroup = f.meta && f.meta.groupId ? this.state.groups.find(g => g.id == f.meta.groupId): null
                                                                const fieldStyles = {
                                                                    left:
                                                                        Math.round(
                                                                            f.left * this.scale,
                                                                        ) + 'px',
                                                                    top:
                                                                        Math.round(f.top * this.scale) +
                                                                        'px',
                                                                    height: '60px',
                                                                    width: '100px',
                                                                }
                                                                const divStyles = {
                                                                    height: 17 * this.scale + 'px',
                                                                    width: 17 * this.scale + 'px',
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                }
                                                                const advancedStyles = {
                                                                    width: '18px',
                                                                    height: '18px',
                                                                    position: 'absolute',
                                                                    right: '20px',
                                                                }
                                                                return (
                                                                    <Draggable
                                                                    key={`${f.id}`}
                                                                    bounds="parent"
                                                                    axis="both"
                                                                    handle={`#handle-${f.id}`}
                                                                    scale={1}
                                                                    onStop={(_, d) => {
                                                                        this.MoveFieldAfterDrag({
                                                                            lastX: d.lastX,
                                                                            lastY: d.lastY,
                                                                            type: 'checkbox',
                                                                            id: f.id,
                                                                        })
                                                                    }}
                                                                >
                                                                    <DraggableField className="draggable" id={`handle-${f.id}`} style={fieldStyles}>
                                                                            <div style={divStyles}>
                                                                                <img src={consentGiven} style={{ width: '100%', pointerEvents: 'none' }} />
                                                                            </div>
                                                                            {
                                                                                f.value && f.value == 'set' ? (
                                                                                    <p style={{ 
                                                                                        marginTop: 8 * this.scale + 'px', 
                                                                                        position: 'absolute',
                                                                                        top: 0,
                                                                                        right: 10 * this.scale + 'px', 
                                                                                    }}>
                                                                                        <span style={{backgroundColor: '#aa0000', padding: "2px", color: '#fff', borderRadius: '3px'}}>
                                                                                            TICKED
                                                                                        </span>
                                                                                    </p>
                                                                                )
                                                                                    : <></>
                                                                            }
                                                                            <p style={{ marginTop: 18 * this.scale + 'px' }}>
                                                                                {relatedGroup ? `${relatedGroup.label} group` : 'NEEDS GROUP'}
                                                                            </p>
                                                                            
                                                                        <DraggableToolbar style={{position: 'absolute', top: "-10px", right: "-10px"}}>
                                                                            <DraggableAdvanced
                                                                                style={advancedStyles}
                                                                                onMouseDown={e => {
                                                                                    e.stopPropagation()
                                                                                }}
                                                                                onClick={() => this.advancedClickFn(f.id, 'checkbox')}
                                                                            >
                                                                                <img
                                                                                    src={advanced}
                                                                                    style={{
                                                                                        width: '10px',
                                                                                    }}
                                                                                />
                                                                            </DraggableAdvanced>
                                                                            <DraggableDelete
                                                                                style={{ padding: '6px' }}
                                                                                onMouseDown={e => {
                                                                                    e.stopPropagation()
                                                                                }}
                                                                                onClick={() => {
                                                                                    this.DeleteField({
                                                                                        type: 'checkbox',
                                                                                        id: f.id,
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={close}
                                                                                    style={{ width: '6px' }}
                                                                                />
                                                                            </DraggableDelete>
                                                                        </DraggableToolbar>
                                                                    </DraggableField>
                                                                </Draggable>
                                                                )
                                                            })
                                                        }
                                                        
                                                        {this.state.newFieldPageNumber == pageIndex + 1 ? (
                                                            <NewFieldModal
                                                                coordinates={{
                                                                    left: this.state.newFieldLeft,
                                                                    top: this.state.newFieldTop,
                                                                }}
                                                                hide={() =>
                                                                    this.setState({ newFieldPageNumber: false })
                                                                }
                                                                createFieldFn={(type, fid) => {
                                                                    this.CreateField({ type, fid })
                                                                }}
                                                                hideComponent={hideFn => (
                                                                    <DraggableDelete
                                                                        style={{ padding: '9px' }}
                                                                        onClick={() => hideFn()}
                                                                    >
                                                                        <img src={close} style={{ width: '9px' }} />
                                                                    </DraggableDelete>
                                                                )}
                                                            />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div
                                                key={pageIndex + '-div2'}
                                                style={{ background: 'rgba(0,0,0,0.2)', height: '1px', width: '100%' }}
                                            />
                                        </React.Fragment>
                                    ))}
                                </Document>
                            </div>
                        ) : (
                            <p>Loading</p>
                        )}
                    </Container>
                </Wrapper>
                {this.state.advancedModalID && this.state.advancedModalType && !this.state.hideAdvanced ? (
                    <AdvancedModal
                        id={this.state.advancedModalID}
                        field={this.state[this.state.advancedModalType].find(f => f.id == this.state.advancedModalID)}
                        updateFieldMetaFn={meta => this.UpdateFieldMeta(meta)}
                        updateCoordinatesFn={newValue => this.UpdateCoordinates(newValue)}
                        hide={() => this.setState({ advancedModalID: null, advancedModalType: null })}
                        hideComponent={hideFn => (
                            <DraggableDelete style={{ padding: '9px' }} onClick={() => hideFn()}>
                                <img src={close} style={{ width: '9px' }} />
                            </DraggableDelete>
                        )}
                        groups={this.state.groups}
                    />
                ) : (
                    <></>
                )}
                {
                    this.state.groupsIsOpen ? (
                        <GroupsModal
                            hideComponent={hideFn => (
                                <DraggableDelete style={{ padding: '9px' }} onClick={() => hideFn()}>
                                    <img src={close} style={{ width: '9px' }} />
                                </DraggableDelete>
                            )}
                            hide={() => this.setState({ groupsIsOpen: false })}
                            groups={this.state.groups}
                            clientActions={[...this.state.freeText, ...this.state.checkbox]}
                            createGroupFn={group => {
                                this.setState({
                                    groups: [...this.state.groups, group],
                                })
                            }}
                            editGroupFn={group => {
                                this.setState({
                                    groups: [
                                        ...this.state.groups.filter(g => g.id != group.id),
                                        group
                                    ]
                                })
                            }}
                            deleteGroupFn={id => {
                                this.setState({
                                    groups: this.state.groups.filter(g => g.id != id),
                                })
                            }}


                        />
                    ) : (
                        <></>
                    )
                }
            </>
        )
    }
}

export default withRouter(Edit)
