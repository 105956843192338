// Dragable measurements are based on the bottom left of the box
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import base64ToBuffer from './base64ToBuffer'

export default async ({ pdfDoc, dataToStamp, scale = 1.5 }) => {
    pdfDoc.registerFontkit(fontkit)
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const loadedCustomFonts = {}

    const fontsToChooseFrom = [
        //Sans
        'lato',
        'merriweather',
        'montserrat',
        'noto-sans',
        'nunito',
        'open-sans',
        'poppins',
        'pt-sans',
        'raleway',
        'roboto',
        'source-sans-pro',
        // Sigs
        'aguafina-script',
        'dancing-script',
        'great-vibes',
        'italianno',
        'tangerine',
        'yellowtail',
    ]

    // Determine which fonts from the selection list are required for this doc and added
    const requiredFonts = []
    dataToStamp.forEach(({ meta }) => {
        const { signatureStyling = false } = meta
        if (signatureStyling && typeof signatureStyling == 'string') {
            if (!requiredFonts.includes(signatureStyling) && fontsToChooseFrom.includes(signatureStyling))
                requiredFonts.push(signatureStyling)
        }
    })

    // Can't be functional, kills some form of loading
    for (let i = 0; i < requiredFonts.length; i++) {
        const bufferData = await fetch(`/font/${requiredFonts[i]}.ttf`).then(res => res.arrayBuffer())
        loadedCustomFonts[requiredFonts[i]] = await pdfDoc.embedFont(bufferData)
    }

    const pages = pdfDoc.getPages()

    const fontSize = 9
    const fieldPaddingBottom = 15
    const fieldPaddingLeft = 5

    const initialHeight = 60 / 1.5
    const initialOffset = 5

    const signatureHeight = 80 / 1.5
    const signatureOffset = 10

    const color = rgb(0.1, 0.1, 0.1)

    pages.forEach((page, pageIndex) => {
        const { width, height } = page.getSize()
        dataToStamp
            .filter(f => f.pageNumber == pageIndex + 1)
            .forEach(field => {
                const { meta } = field

                // Null is technically an object due to JS been shit
                const notANumber =
                    typeof field.value === 'undefined' ||
                    (typeof field.value === 'string' && field.value.length == 0) ||
                    typeof field.value === 'object'

                let output = `${meta.prefix && !notANumber ? meta.prefix : ''}${
                    notANumber
                        ? ''
                        : typeof field.meta.decimals === 'undefined'
                        ? field.value
                        : parseFloat(field.value).toLocaleString(undefined, {
                              minimumFractionDigits: field.meta.decimals,
                              maximumFractionDigits: field.meta.decimals,
                          })
                }${meta.postfix && !notANumber ? meta.postfix : ''}`

                let colorToUse = color
                if (meta.color) {
                    const rgbs = meta.color.trim().split(',')
                    // gotta love string => int coercion
                    colorToUse = rgb(rgbs[0] / 255, rgbs[1] / 255, rgbs[2] / 255)
                }

                const options = {
                    x: Math.round(field.left + fieldPaddingLeft),
                    y: Math.round(height - field.top - (meta.fontSize ? meta.fontSize : fontSize) - fieldPaddingBottom),
                    size: meta.fontSize ? meta.fontSize : fontSize,
                    lineHeight: meta.fontSize ? meta.fontSize * 1.3 : fontSize * 1.3,
                    font:
                        meta.signatureStyling &&
                        typeof meta.signatureStyling == 'string' &&
                        requiredFonts.includes(meta.signatureStyling)
                            ? loadedCustomFonts[meta.signatureStyling]
                            : helveticaFont,
                    color: colorToUse,
                }
                if (meta.maxWidth) {
                    options.maxWidth = meta.maxWidth
                }
                page.drawText(output.replace(/<br>/g, '\n'), options)
            })
    })

    return pdfDoc
}
