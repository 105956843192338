import React, { Component } from 'react' // eslint-disable-line
import tw, { styled } from 'twin.macro'

const ModalBlock = tw.div`
    inline-block
    bg-gray-100 rounded-md 
    border-gray-200
    border-2
    px-4 pt-5 pb-4 text-left  shadow-md transform transition-all
`
const Container = styled.div`
    ${tw`flex flex-col`}    
`
const ButtonContainer = tw.div`
    flex 
    flex-col
`
const Button = tw.button`
    w-full inline-flex justify-center rounded-md border border-transparent shadow-sm 
    py-2 px-2 text-base font-medium 
    text-white
    bg-blue-600 hover:bg-blue-700 focus:outline-none 
    text-xs
    mt-3 
`
const Label = tw.label`
    block text-sm font-medium text-gray-500 text-xs mt-2 pl-1
`

const Input = tw.input`
    mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-200 border-2 rounded-md py-1 px-2
`

const TextArea = tw.textarea`
    mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-200 border-2 rounded-md py-1 px-2
`
const Select = tw.select`
    mt-3 block focus:ring-indigo-500 focus:border-indigo-500 w-full sm:max-w-xs sm:text-sm border-gray-200 border-2 rounded-md px-2 py-1
`

class MetaModal extends Component {
    constructor(props) {
        super(props)
        
    }

    render() {
        const { hideComponent, hide, metaData, editFieldFn } = this.props
        const {name, type, secondaryType, version, visibilityRule} = metaData
        return (
            <ModalBlock
                role="dialog"
                style={{
                    minWidth: '300px',
                    top: '20px',
                    left: '20px',
                    position: 'fixed',
                    zIndex: 8888,
                }}
            >
                <div style={{ position: 'absolute', top: -10, right: -10 }}>
                    {hideComponent(() => {
                        hide()
                    })}
                </div>
                <Container>
                    <Label>Name</Label>
                    <Input
                        type="text"
                        value={name}    
                        onChange={e => editFieldFn("name", e.target.value)}                    
                    />
                    <Label>Type</Label>
                    <Select
                        value={type}
                        onChange={e => editFieldFn("type", e.target.value)}
                    >
                        <option value="HIA">HIA</option>
                        <option value="NEM Agreement">NEM Agreement</option>
                        <option value="Miscellaneous">Miscellaneous</option>
                        <option value="HOA">HOA</option>
                        <option value="HOI">HOI</option>
                    </Select>

                    <Label>Secondary Type</Label>
                    <Input
                        type="text"
                        value={secondaryType}
                        onChange={e => editFieldFn("secondaryType", e.target.value)}                    
                    />

                    <Label>Version</Label>
                    <Input
                        type="text"
                        value={version}
                        onChange={e => editFieldFn("version", e.target.value)}                    
                    />

                    <Label>Visibility Rule</Label>
                    <TextArea
                        type="text"
                        value={visibilityRule}
                        style={{height: '100px'}}
                        onChange={e => editFieldFn("visibilityRule", e.target.value)}                    
                    />
                </Container>
            </ModalBlock>
        )
    }
}

export default MetaModal
