import tw from 'twin.macro'

export const Wrapper = tw.div`
  flex flex-col items-center justify-center h-screen
`

export const Container = tw.div`
  flex flex-col justify-center h-full space-y-5
`
export const DraggableField = tw.div`
    absolute
    w-auto
    rounded-md
    shadow-md
    border-2
    flex
    flex-col
    justify-between
    border-gray-300
    bg-gray-100
    bg-opacity-70
`



export const DraggableToolbar = tw.div`
    flex 
    justify-between
    items-center
    
`
export const DraggableDelete = tw.a`
    bg-red-600
    inline-block
    rounded-full
    cursor-pointer
    hover:bg-red-800
    flex 
    justify-center
    items-center
`

export const DraggableAdvanced = tw(DraggableDelete)`
    bg-blue-600
    hover:bg-blue-800
`