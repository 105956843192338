import React from 'react' // eslint-disable-line
import tw from 'twin.macro'
import { withRouter } from 'react-router'

import { PDFDocument } from 'pdf-lib' // Used for modications to underlying PDF

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import Draggable from 'react-draggable' // draggable lil divs
import { Helmet } from 'react-helmet'

import Header from './components/Header'
import Notification from './components/Notification'

import close from './icons/close.svg'
import advanced from './icons/advanced.svg'

import AdvancedModal from './components/AdvancedModal'
import NewFieldModal from './components/NewFieldModal'
import AuthModal from './components/AuthModal'
import MetaModal from './components/MetaModal'

import base64ToArrayBuffer from './utils/base64ToBuffer'

import { instanceOf } from 'prop-types';

import { withCookies, Cookies } from 'react-cookie';

import { getV2, putV2, login } from './utils/networkV2'

class EditV2 extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props) {
        super(props)

        const { cookies } = props;
        this.state = {
            pageCount: null,
            pageNumber: 1,
            fields: [],
            qb: [],
            initial: [],
            signature: [],
            renderDrags: true,
            pdfCreated: false,
            notification: null,
            hideAdvanced: false,
            showAuthModal: false,
            authModalEmail: "",
            authModalPassword: "",
            token: null,
            showMetaModal: false,
            documentMeta : {}
            
        }
        this.fontSize = 9
        this.scale = 1.5
    }


    getData = async (renderDocument = false) => {
        if (!this.state.token){
            alert("You must be logged in to get contracts")
            return
        }

        const { contractId, proposalRecordId } = this.props.match.params

        const data = await getV2({
            proposalRecordId,
            contractId,
            token: this.state.token
        })


        this.setState(
            {
                fields: data.Fields,
                pageCount: data.PageCount,
                qb: data.Fields.filter(f => f.type == 'QB'),
                initial: data.Fields.filter(f => f.type == 'Initial'),
                signature: data.Fields.filter(f => f.type == 'Signature'),
                shape: data.Fields.filter(f => f.type == 'Shape'),
                proposalRecordID: proposalRecordId,
                documentMeta: data.DocumentMeta
            },
            async () => {
                if (renderDocument){
                    await this.renderPDF(data.DocumentData)
                }
            },
        )

    }

    componentDidMount() {
        // Get params from router path

        const { cookies } = this.props;
        const authModalEmail = cookies.get("authEmail")
        const authToken = cookies.get("authToken")

        console.log("didMount", {authModalEmail, authToken})
        if(authModalEmail && authToken){
            this.setState({
                authModalEmail,
                token: authToken
            }, () => this.getData(true))
            
        }
        
        
    }

    async renderPDF(rawDocBase64) {
        const arrayBuffer = base64ToArrayBuffer({ base64: rawDocBase64 })
        const pdfDoc = await PDFDocument.load(arrayBuffer)

        this.docBase64 = await pdfDoc.saveAsBase64({ dataUri: true })
        this.pdfDoc = pdfDoc

        this.setState({ pdfCreated: true })
    }

    onDocumentLoadSuccess = props => {
        console.log({ onDocumentLoadProps: props })
        const { numPages } = props
        const pageCount = numPages
        this.setState({ pageCount })
    }

    onPageOneLoadSuccess = ({ originalHeight, originalWidth }) => {
        this.setState({ originalWidth, originalHeight })
    }



    CreateField = async ({ type, fid }) => {
        const newObject = {
            // pseudo Hash/ID
            id: Math.floor(Math.random() * 200000000),
            top: Math.round(this.state.newFieldTop / this.scale),
            left: Math.round(this.state.newFieldLeft / this.scale),
            type: type == 'qb' ? 'QB' : type == 'initial' ? 'Initial' : type == 'signature' ? 'Signature' : 'Shape',
            pageNumber: this.state.newFieldPageNumber,
            meta: {},
        }

        const stateReset = {
            newFieldLeft: null,
            newFieldTop: null,
            newFieldPageNumber: null,
        }

        if (fid) {
            newObject.fid = parseInt(fid)
            const fields = [...this.state.qb, newObject, ...this.state.initial, ...this.state.signature]
            const { proposalRecordID } = this.props.match.params

            // Get updated field object
            const updated = await putV2({
                proposalRecordID: parseInt(proposalRecordID),
                fields: fields,
                //.. need the other edit fields.
            }).catch(err => {
                this.setNotification({
                    status: 'bad',
                    message: `Data not saved.${'\n'}Please ensure your password is correct`,
                })
            })

            if (updated && updated.Status == 200 && !updated.HasError) {
                this.setNotification({ status: 'saved', message: 'Your data was saved' })
            }

            this.setState({
                qb: updated.fields.filter(f => f.type == 'QB'),
                ...stateReset,
            })
        } else {
            this.setState({
                [type]: [...this.state[type], newObject],
                ...stateReset,
            })
        }
    }

    MoveFieldAfterDrag = ({ lastX, lastY, type, id }) => {
        const fieldMoved = this.state[type].find(f => f.id == id)
        const oldFields = this.state[type].filter(f => f.id != id)

        fieldMoved.left += Math.round(lastX / this.scale)
        fieldMoved.top += Math.round(lastY / this.scale)

        this.setState(
            {
                renderDrags: false,
                hideAdvanced: true, // Forces the advanced modal to re-render if the field has been dragged while it has been open
            },
            () => {
                this.setState({
                    [type]: [...oldFields, fieldMoved],
                    renderDrags: true,
                    hideAdvanced: false,
                })
            },
        )
    }

    UpdateCoordinates = ({ axis, value }) => {
        const { advancedModalID, advancedModalType } = this.state

        const fieldToUpdate = { ...this.state[advancedModalType].find(f => f.id == advancedModalID) }
        const otherFields = this.state[advancedModalType].filter(f => f.id != advancedModalID)

        fieldToUpdate[axis] = value ? parseFloat(value) : 0

        this.setState(
            {
                renderDrags: false,
            },
            () => {
                this.setState({
                    [advancedModalType]: [...otherFields, fieldToUpdate],
                    renderDrags: true,
                })
            },
        )
    }

    UpdateFieldMeta = meta => {
        // Add the meta to the field that is currently the currently active
        const { advancedModalID, advancedModalType } = this.state

        const fieldToUpdate = { ...this.state[advancedModalType].find(f => f.id == advancedModalID) }
        const otherFields = this.state[advancedModalType].filter(f => f.id != advancedModalID)

        // make this better when time
        const cleanedMeta = {}

        if (meta.fontSize) {
            cleanedMeta.fontSize = parseInt(meta.fontSize)
        }
        if (meta.maxWidth) {
            cleanedMeta.maxWidth = parseInt(meta.maxWidth)
        }
        if (meta.prefix) {
            cleanedMeta.prefix = meta.prefix
        }
        if (meta.postfix) {
            cleanedMeta.postfix = meta.postfix
        }
        if (meta.color) {
            cleanedMeta.color = meta.color
        }
        if (meta.decimals) {
            cleanedMeta.decimals = parseInt(meta.decimals)
        }

        if (meta.signatureStyling) {
            cleanedMeta.signatureStyling = meta.signatureStyling
        }

        if (meta.secondaryType) {
            cleanedMeta.secondaryType = meta.secondaryType
        }

        fieldToUpdate.meta = cleanedMeta

        this.setState(
            {
                renderDrags: false,
            },
            () => {
                this.setState({
                    [advancedModalType]: [...otherFields, fieldToUpdate],
                    renderDrags: true,
                })
            },
        )
    }

    DeleteField = ({ type, id }) => {
        // If you're deleting the field you're editing, disable modal first
        if (id == this.state.advancedModalID) {
            this.setState({
                advancedModalID: null,
                advancedModalType: null,
                [type]: [...this.state[type].filter(f => f.id != id)],
            })
        } else {
            this.setState({
                [type]: [...this.state[type].filter(f => f.id != id)],
            })
        }
    }

    setNotification = ({ status, message }) => {
        this.setState(
            {
                notification: {
                    status,
                    message,
                },
            },
            () => {
                setTimeout(() => {
                    this.setState({ notification: null })
                }, 1500)
            },
        )
    }

    login = async () => {

        const response = await login({
            email: this.state.authModalEmail,
            password: this.state.authModalPassword
        })

        if(response && response.token){
            this.setState({
                token: response.token,
            })

            console.log({authModalEmail: this.state.authModalEmail, token: response.token})
            this.props.cookies.set("authToken", response.token, {path: "/"});
            this.props.cookies.set("authEmail", this.state.authModalEmail, { path: "/" });

            setTimeout(
                () => {
                    const { cookies } = this.props;
                    const authModalEmail = cookies.get("authEmail")
                    const authToken = cookies.get("authToken")

                    console.log("After login", { authModalEmail, authToken })
                }, 5000)

        }
        
    }
    saveDataToServer = async () => {
        if(!this.state.token){
            alert("You must be logged in to edit contracts")
            return
        }

        const fields = [...this.state.qb, ...this.state.initial, ...this.state.signature]

        const wasUpdated = await putV2({
            proposalRecordID: parseInt(this.state.proposalRecordID),
            fields,
            documentMeta: this.state.documentMeta,
            token: this.state.token
        }).catch(err => {
            this.setNotification({
                status: 'bad',
                message: `Data not saved.${'\n'}Please ensure your password is correct`,
            })
        })

        if(wasUpdated){
            this.getData(false)
        }

    }

    saveFieldsToLocalFile = () => {
        const fields = [...this.state.qb, ...this.state.initial, ...this.state.signature]
        const fileData = JSON.stringify(fields, null, 2)
        const blob = new Blob([fileData], { type: 'text/plain' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
    }

    capitalize = s => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    render() {
        const viewerStyles = {
            overflowY: 'scroll',
            display: 'flex',
            justifyContent: 'center',
            borderTop: '1px solid rgba(0,0,0,0.2)',
            borderLeft: '1px solid rgba(0,0,0,0.2)',
            marginTop: '63px',
        }

        console.log({ state: this.state })

        return (
            <>
                <Helmet>
                    <title>Sunobi Document Editing</title>
                </Helmet>
                {this.state.notification ? (
                    <Notification status={this.state.notification.status} message={this.state.notification.message} />
                ) : (
                    <></>
                )}
                {this.state.pdfCreated ? (
                    <Header
                        proposalRecordID={this.state.proposalRecordID}
                        fieldCount={this.state.qb.length}
                        initialCount={this.state.initial.length}
                        signatureCount={this.state.signature.length}
                        shapeCount={this.state.shape.length}
                        saveFn={this.saveDataToServer}
                        localSaveFn={this.saveFieldsToLocalFile}
                        password={this.state.password}
                        updatePasswordFn={password => this.setState({ password })}
                        isV2={true}
                        showAuthModalFn={() => this.setState({showAuthModal: true})}
                        hasToken={this.state.token}
                        authEmail={this.state.authModalEmail}
                        showMetaFn={() => this.setState({showMetaModal: true})}

                    />
                ) : (
                    <></>
                )}
                <Wrapper>
                    <Container>
                        {this.state.pdfCreated ? (
                            <div style={viewerStyles}>
                                <Document file={this.docBase64} onLoadSuccess={this.onDocumentLoadSuccess}>
                                    {[...Array(this.state.pageCount)].map((_, pageIndex) => (
                                        <React.Fragment key={pageIndex + '-fragment'}>
                                            <div style={{ position: 'relative' }} key={pageIndex + '-div'}>
                                                <Page
                                                    pageNumber={pageIndex + 1}
                                                    scale={1.5}
                                                    key={pageIndex + '-page'}
                                                    onRenderSuccess={props => {
                                                        if (pageIndex == 0) this.onPageOneLoadSuccess(props)
                                                    }}
                                                    // False
                                                    renderAnnotationLayer={false}
                                                    renderInteractiveForms={false}
                                                    renderTextLayer={false}
                                                />
                                                {this.state.originalWidth && this.state.originalHeight ? (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                        onContextMenu={e => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                newFieldLeft: e.nativeEvent.layerX,
                                                                newFieldTop: e.nativeEvent.layerY,
                                                                newFieldPageNumber: pageIndex + 1,
                                                            })
                                                        }}
                                                    >
                                                        {
                                                            // Actual Fields
                                                            this.state.qb
                                                                .filter(f => f.pageNumber == pageIndex + 1)
                                                                .map(f =>
                                                                    this.state.renderDrags ? (
                                                                        <Draggable
                                                                            key={`${f.id}`}
                                                                            bounds="parent"
                                                                            axis="both"
                                                                            handle={`#handle-${f.id}`}
                                                                            scale={1}
                                                                            //grid={[1, 1]}
                                                                            onStop={(_, d) => {
                                                                                this.MoveFieldAfterDrag({
                                                                                    lastX: d.lastX,
                                                                                    lastY: d.lastY,
                                                                                    type: 'qb',
                                                                                    id: f.id,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DraggableField
                                                                                className="draggable"
                                                                                id={`handle-${f.id}`}
                                                                                key={`handle-${f.id}`}
                                                                                style={{
                                                                                    left:
                                                                                        Math.round(
                                                                                            f.left * this.scale,
                                                                                        ) + 'px',
                                                                                    top:
                                                                                        Math.round(f.top * this.scale) +
                                                                                        'px',
                                                                                    minHeight: '45px',
                                                                                }}
                                                                            >
                                                                                <DraggableToolbar
                                                                                    style={{ minWidth: '50px' }}
                                                                                    key={`toolbar-${f.id}`}
                                                                                >
                                                                                    <p>{f.fid}</p>
                                                                                    <div
                                                                                        style={{
                                                                                            marginTop: '-14px',
                                                                                            display: 'flex',
                                                                                        }}
                                                                                    >
                                                                                        <DraggableAdvanced
                                                                                            style={{
                                                                                                width: '18px',
                                                                                                height: '18px',
                                                                                                marginRight: '2px',
                                                                                            }}
                                                                                            onMouseDown={e => {
                                                                                                e.stopPropagation()
                                                                                            }}
                                                                                            onClick={() =>
                                                                                                this.setState(
                                                                                                    {
                                                                                                        advancedModalID: null,
                                                                                                        advancedModalType: null,
                                                                                                    },
                                                                                                    () =>
                                                                                                        this.setState({
                                                                                                            advancedModalID:
                                                                                                                f.id,
                                                                                                            advancedModalType:
                                                                                                                'qb',
                                                                                                        }),
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <img
                                                                                                src={advanced}
                                                                                                style={{
                                                                                                    width: '10px',
                                                                                                }}
                                                                                            />
                                                                                        </DraggableAdvanced>
                                                                                        <DraggableDelete
                                                                                            style={{
                                                                                                width: '18px',
                                                                                                height: '18px',
                                                                                                marginRight: '-14px',
                                                                                            }}
                                                                                            onMouseDown={e => {
                                                                                                e.stopPropagation()
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                this.DeleteField({
                                                                                                    type: 'qb',
                                                                                                    id: f.id,
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src={close}
                                                                                                style={{ width: '6px' }}
                                                                                            />
                                                                                        </DraggableDelete>
                                                                                    </div>
                                                                                </DraggableToolbar>
                                                                                <p
                                                                                    style={{
                                                                                        fontSize:
                                                                                            (f.meta.fontSize
                                                                                                ? f.meta.fontSize
                                                                                                : this.fontSize) *
                                                                                                this.scale +
                                                                                            'px',
                                                                                        width: f.meta.maxWidth
                                                                                            ? f.meta.maxWidth *
                                                                                                  this.scale +
                                                                                              'px'
                                                                                            : 'auto',
                                                                                        color: f.meta.color
                                                                                            ? 'rgb(' +
                                                                                              f.meta.color +
                                                                                              ')'
                                                                                            : 'initial',
                                                                                    }}
                                                                                >
                                                                                    {f.meta.prefix && f.value
                                                                                        ? f.meta.prefix
                                                                                        : ''}
                                                                                    {!f.value
                                                                                        ? ''
                                                                                        : typeof f.meta.decimals ===
                                                                                          'undefined'
                                                                                        ? f.value
                                                                                        : parseFloat(
                                                                                              f.value,
                                                                                          ).toLocaleString(undefined, {
                                                                                              minimumFractionDigits:
                                                                                                  f.meta.decimals,
                                                                                              maximumFractionDigits:
                                                                                                  f.meta.decimals,
                                                                                          })}
                                                                                    {f.meta.postfix && f.value
                                                                                        ? f.meta.postfix
                                                                                        : ''}
                                                                                </p>
                                                                            </DraggableField>
                                                                        </Draggable>
                                                                    ) : (
                                                                        <></>
                                                                    ),
                                                                )
                                                        }
                                                        {
                                                            // Initial Placement
                                                            this.state.initial
                                                                .filter(f => f.pageNumber == pageIndex + 1)
                                                                .map(f =>
                                                                    this.state.renderDrags ? (
                                                                        <Draggable
                                                                            key={`${f.id}`}
                                                                            bounds="parent"
                                                                            axis="both"
                                                                            handle={`#handle-${f.id}`}
                                                                            scale={1}
                                                                            onStop={(_, d) => {
                                                                                this.MoveFieldAfterDrag({
                                                                                    lastX: d.lastX,
                                                                                    lastY: d.lastY,
                                                                                    type: 'initial',
                                                                                    id: f.id,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DraggableField
                                                                                className="draggable"
                                                                                id={`handle-${f.id}`}
                                                                                style={{
                                                                                    left:
                                                                                        Math.round(
                                                                                            f.left * this.scale,
                                                                                        ) + 'px',
                                                                                    top:
                                                                                        Math.round(f.top * this.scale) +
                                                                                        'px',
                                                                                    height: '60px',
                                                                                    width: '77px',
                                                                                }}
                                                                            >
                                                                                <DraggableToolbar>
                                                                                    <p>
                                                                                        {f.meta &&
                                                                                        f.meta.secondaryType ==
                                                                                            'secondary'
                                                                                            ? 'Co-'
                                                                                            : ''}
                                                                                        Initial
                                                                                    </p>
                                                                                    <DraggableAdvanced
                                                                                        style={{
                                                                                            width: '18px',
                                                                                            height: '18px',
                                                                                            position: 'absolute',
                                                                                            right: '14px',
                                                                                            marginTop: '-14px',
                                                                                        }}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            this.setState(
                                                                                                {
                                                                                                    advancedModalID: null,
                                                                                                    advancedModalType: null,
                                                                                                },
                                                                                                () =>
                                                                                                    this.setState({
                                                                                                        advancedModalID:
                                                                                                            f.id,
                                                                                                        advancedModalType:
                                                                                                            'initial',
                                                                                                    }),
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={advanced}
                                                                                            style={{
                                                                                                width: '10px',
                                                                                            }}
                                                                                        />
                                                                                    </DraggableAdvanced>
                                                                                    <DraggableDelete
                                                                                        style={{
                                                                                            padding: '6px',
                                                                                            marginTop: '-14px',
                                                                                            marginRight: '-14px',
                                                                                        }}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.DeleteField({
                                                                                                type: 'initial',
                                                                                                id: f.id,
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={close}
                                                                                            style={{ width: '6px' }}
                                                                                        />
                                                                                    </DraggableDelete>
                                                                                </DraggableToolbar>
                                                                            </DraggableField>
                                                                        </Draggable>
                                                                    ) : (
                                                                        <></>
                                                                    ),
                                                                )
                                                        }
                                                        {
                                                            // Signature Placement
                                                            this.state.signature
                                                                .filter(f => f.pageNumber == pageIndex + 1)
                                                                .map(f =>
                                                                    this.state.renderDrags ? (
                                                                        <Draggable
                                                                            key={`${f.id}`}
                                                                            bounds="parent"
                                                                            axis="both"
                                                                            handle={`#handle-${f.id}`}
                                                                            scale={1}
                                                                            onStop={(_, d) => {
                                                                                this.MoveFieldAfterDrag({
                                                                                    lastX: d.lastX,
                                                                                    lastY: d.lastY,
                                                                                    type: 'signature',
                                                                                    id: f.id,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DraggableField
                                                                                className="draggable"
                                                                                id={`handle-${f.id}`}
                                                                                style={{
                                                                                    left:
                                                                                        Math.round(
                                                                                            f.left * this.scale,
                                                                                        ) + 'px',
                                                                                    top:
                                                                                        Math.round(f.top * this.scale) +
                                                                                        'px',
                                                                                    height: '80px',
                                                                                    width: '109px',
                                                                                }}
                                                                            >
                                                                                <DraggableToolbar>
                                                                                    <p>
                                                                                        {f.meta &&
                                                                                        f.meta.secondaryType ==
                                                                                            'secondary'
                                                                                            ? 'Co '
                                                                                            : ''}
                                                                                        Signature
                                                                                    </p>
                                                                                    <DraggableAdvanced
                                                                                        style={{
                                                                                            width: '18px',
                                                                                            height: '18px',
                                                                                            position: 'absolute',
                                                                                            right: '28px',
                                                                                        }}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() =>
                                                                                            this.setState(
                                                                                                {
                                                                                                    advancedModalID: null,
                                                                                                    advancedModalType: null,
                                                                                                },
                                                                                                () =>
                                                                                                    this.setState({
                                                                                                        advancedModalID:
                                                                                                            f.id,
                                                                                                        advancedModalType:
                                                                                                            'signature',
                                                                                                    }),
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={advanced}
                                                                                            style={{
                                                                                                width: '10px',
                                                                                            }}
                                                                                        />
                                                                                    </DraggableAdvanced>
                                                                                    <DraggableDelete
                                                                                        style={{ padding: '6px' }}
                                                                                        onMouseDown={e => {
                                                                                            e.stopPropagation()
                                                                                        }}
                                                                                        onClick={() => {
                                                                                            this.DeleteField({
                                                                                                type: 'signature',
                                                                                                id: f.id,
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        <img
                                                                                            src={close}
                                                                                            style={{ width: '6px' }}
                                                                                        />
                                                                                    </DraggableDelete>
                                                                                </DraggableToolbar>
                                                                            </DraggableField>
                                                                        </Draggable>
                                                                    ) : (
                                                                        <></>
                                                                    ),
                                                                )
                                                        }
                                                        {this.state.newFieldPageNumber == pageIndex + 1 ? (
                                                            <NewFieldModal
                                                                coordinates={{
                                                                    left: this.state.newFieldLeft,
                                                                    top: this.state.newFieldTop,
                                                                }}
                                                                hide={() =>
                                                                    this.setState({ newFieldPageNumber: false })
                                                                }
                                                                createFieldFn={(type, fid) => {
                                                                    this.CreateField({ type, fid })
                                                                }}
                                                                hideComponent={hideFn => (
                                                                    <DraggableDelete
                                                                        style={{ padding: '9px' }}
                                                                        onClick={() => hideFn()}
                                                                    >
                                                                        <img src={close} style={{ width: '9px' }} />
                                                                    </DraggableDelete>
                                                                )}
                                                            />
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                            <div
                                                key={pageIndex + '-div2'}
                                                style={{ background: 'rgba(0,0,0,0.2)', height: '1px', width: '100%' }}
                                            />
                                        </React.Fragment>
                                    ))}
                                </Document>
                            </div>
                        ) : (
                            <p>Loading</p>
                        )}
                    </Container>
                </Wrapper>
                {this.state.advancedModalID && this.state.advancedModalType && !this.state.hideAdvanced ? (
                    <AdvancedModal
                        id={this.state.advancedModalID}
                        field={this.state[this.state.advancedModalType].find(f => f.id == this.state.advancedModalID)}
                        updateFieldMetaFn={meta => this.UpdateFieldMeta(meta)}
                        updateCoordinatesFn={newValue => this.UpdateCoordinates(newValue)}
                        hide={() => this.setState({ advancedModalID: null, advancedModalType: null })}
                        hideComponent={hideFn => (
                            <DraggableDelete style={{ padding: '9px' }} onClick={() => hideFn()}>
                                <img src={close} style={{ width: '9px' }} />
                            </DraggableDelete>
                        )}
                    />
                ) : (
                    <></>
                )}

                {!this.state.token || this.state.showAuthModal ? (
                    <AuthModal
                        updateStateFn={(key, value) => this.setState({[key]: value})}
                        loginFn={this.login}
                        email={this.state.authModalEmail}
                        password={this.state.authModalPassword}
                        hasToken={this.state.token}
                        hideComponent={hideFn => (
                            <DraggableDelete style={{ padding: '9px' }} onClick={() => hideFn()}>
                                <img src={close} style={{ width: '9px' }} />
                            </DraggableDelete>
                        )}
                        hide={() => this.setState({showAuthModal: false})}
                        deleteFn={() => {
                            this.setState({token: null})
                            this.props.cookies.remove("authToken");
                            this.props.cookies.remove("authEmail");
                        }}
                    />
                ) : (
                    <></>
                )}

                {this.state.showMetaModal ? (
                    <MetaModal
                        metaData={this.state.documentMeta}
                        updateStateFn={(key, value) => this.setState({ [key]: value })}
                        hasToken={this.state.token}
                        hideComponent={hideFn => (
                            <DraggableDelete style={{ padding: '9px' }} onClick={
                                () => this.setState({ showMetaModal: false })
                            }>
                                <img src={close} style={{ width: '9px' }} />
                            </DraggableDelete>
                        )}
                        editFieldFn={
                            (subKey, value) => this.setState({
                                documentMeta: {
                                    ...this.state.documentMeta,
                                    [subKey]: value
                                }
                            })
                        }
                    />
                ) : (
                    <></>
                )}
            </>
        )
    }
}

export default withCookies(withRouter(EditV2))

const Wrapper = tw.div`
  flex flex-col items-center justify-center h-screen
`
const Container = tw.div`
  flex flex-col justify-center h-full space-y-5
`

const DraggableField = tw.div`
    absolute
    w-auto
    rounded-md
    shadow-md
    border-2
    flex
    flex-col
    justify-between
    border-gray-300
    bg-gray-100
    bg-opacity-70
`

const DraggableToolbar = tw.div`
    flex 
    justify-between
    items-center
    
`
const DraggableDelete = tw.a`
    bg-red-600
    inline-block
    rounded-full
    cursor-pointer
    hover:bg-red-800
    flex 
    justify-center
    items-center
`

const DraggableAdvanced = tw(DraggableDelete)`
    bg-blue-600
    hover:bg-blue-800
`