import axios from 'axios'

const AUTH_SERVER = "https://api-internal.sunobiapp.com/authenticate"
const service =
    process.env.REACT_APP_IS_DEPLOYED && process.env.REACT_APP_IS_DEPLOYED == 'whooRah'
        ? 'https://integrations.sunobiapp.com/contract_document.v1.contract_document_service'
        : 'http://localhost:9090/contract_document.v1.contract_document_service'

const metaTranslate = ({ fields, encode }) => {
    if (encode)
        return fields.map(f => ({
            ...f,
            meta: Object.keys(f.meta).length ? JSON.stringify(f.meta) : '',
        }))
    // Decode
    return fields.map(f => ({
        ...f,
        meta: f.meta && f.meta.length ? JSON.parse(f.meta) : {},
    }))
}





const getV2 = async body => {
    const url = `${service}/GetContractForMarkup`


    const payload = {
        token: body.token,
        contractId : body.contractId,
        proposalRecordId: body.proposalRecordId
    }
    const response = await axios.post(url, payload)
    const dataToReturn = {
        ...response.data,
        Fields: metaTranslate({ fields: response.data.Fields, encode: false }),
    }
    return dataToReturn
}

const putV2 = async body => {

    const url = `${service}/Edit`
    const encodedFields = metaTranslate({ fields: body.fields, encode: true })

    const req = {
        token: body.token,
        ...body.documentMeta,
        markup: JSON.stringify(encodedFields)
    }

    delete req.dateCreated
    delete req.dateUpdated
    delete req.url


    console.log({putRequest: req})

    try{
        const response = await axios.post(url, req)
        console.log({putResponse: response})

        return true;
    }
    catch(err){
        console.log({putErr: err})
    }
}

const login = async loginBody => {
    
    try{
        const response = await axios.post(AUTH_SERVER, loginBody)

        return response.data
    }
    catch(e){
        console.log(e)
        return null
    }
}



export { getV2, putV2, login }
