import React, { Component } from 'react' // eslint-disable-line
import tw, { styled } from 'twin.macro'

const ModalBlock = tw.div`
    inline-block
    bg-gray-100 rounded-md 
    border-gray-200
    border-2
    px-4 pt-5 pb-4 text-left  shadow-xl transform transition-all
`
const Container = tw.div`
    flex flex-col
`
const Title = tw.h3`
    text-base font-normal text-gray-500 mt-2 ml-1
`
const ButtonContainer = tw.div`
    flex flex-col
`
const Button = tw.button`
    w-full inline-flex justify-center rounded-md border border-transparent shadow-sm 
    py-2 text-base font-medium 
    text-white
    bg-blue-600 hover:bg-blue-700 focus:outline-none 
    col-start-2 text-xs
`
const ButtonCancel = tw.button`
    mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2 bg-white 
    text-base font-medium text-gray-700 
    hover:bg-gray-50 focus:outline-none 
    mt-0 col-start-1 text-xs
`
const Input = tw.input`
    mb-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border-gray-200 border-2 rounded-md py-1 px-2
`

class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            creatingQuickbaseField: false,
        }
    }

    // CallBack when user clicks the right hand button
    confirmQuickBaseFID = () => {
        const f_id = this._fid.value
        if (!f_id.length) {
            alert('Put a value in!')
            return
        }
        this.props.createFieldFn('qb', f_id)
        this.props.hide()
        this.setState({
            creatingQuickbaseField: false,
        })
    }

    render() {
        const { type, show, hideComponent, coordinates } = this.props
        const { top, left } = coordinates
        // Show is inherited from the parent
        const Wrapper = styled.div(() => [tw`fixed z-10 inset-0 overflow-y-auto`])

        return (
            <ModalBlock
                role="dialog"
                style={{
                    minWidth: '150px',
                    top: top + 'px',
                    left: left + 'px',
                    position: 'absolute',
                    zIndex: 9999,
                }}
            >
                <div style={{ position: 'absolute', top: -10, right: -10 }}>
                    {hideComponent(() => {
                        this.setState({
                            creatingQuickbaseField: false,
                        })
                        this.props.hide()
                    })}
                </div>
                <Container>
                    {!this.state.creatingQuickbaseField ? (
                        <>
                            <ButtonCancel
                                onClick={() => {
                                    this.setState({
                                        creatingQuickbaseField: true,
                                    })
                                }}
                                style={{ marginBottom: '15px' }}
                            >
                                Quickbase
                            </ButtonCancel>
                            <Button
                                onClick={() => {
                                    this.props.createFieldFn('initial', null)
                                    this.props.hide()
                                }}
                                style={{ marginBottom: '15px' }}
                            >
                                Initial
                            </Button>
                            <Button
                                onClick={() => {
                                    this.props.createFieldFn('signature', null)
                                    this.props.hide()
                                }}
                                style={{ marginBottom: '15px' }}
                            >
                                Signature
                            </Button>
                            <Button
                                onClick={() => {
                                    this.props.createFieldFn('shape', null)
                                    this.props.hide()
                                }}
                                style={{ marginBottom: '15px' }}
                            >
                                Shape
                            </Button>
                            <Button
                                onClick={() => {
                                    this.props.createFieldFn('freeText', null)
                                    this.props.hide()
                                }}
                                style={{ marginBottom: '15px' }}
                            >
                                Free Text
                            </Button>
                            <Button
                                onClick={() => {
                                    this.props.createFieldFn('checkbox', null)
                                    this.props.hide()
                                }}
                                style={{ marginBottom: '15px' }}
                            >
                                Checkbox
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                </Container>
                <ButtonContainer>
                    {this.state.creatingQuickbaseField ? (
                        <>
                            <Input type="text" ref={input => (this._fid = input)} placeholder={'FID'} />
                            <Button type="button" className="" onClick={this.confirmQuickBaseFID}>
                                Confirm
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                </ButtonContainer>
            </ModalBlock>
        )
    }
}

export default Modal
