import React, { Fragment } from 'react' // eslint-disable-line
import tw, {styled} from 'twin.macro'
import { withRouter } from 'react-router'

import { PDFDocument } from 'pdf-lib' // Used for modications to underlying PDF
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { Helmet } from 'react-helmet'
import SignHeader from './components/SignHeader'
import Notification from './components/Notification'
import Modal from './components/Modal'
import ConsentModal from './components/ConsentModal'
import Thanks from './components/Thanks'
import WrappedPage from './components/WrappedPage'

import DemoMode from './components/DemoMode'

import consentCheckbox from './icons/consentCheckbox.svg'
import consentGiven from './icons/consentGiven.svg'

import base64ToArrayBuffer from './utils/base64ToBuffer'
import stampWithData from './utils/stampWithData'

import rightArrow from './icons/BYA-v2.gif'
import tick from './icons/tick.svg'

import { get, post } from './utils/network'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            docBase64: null,
            pdfDoc: null,
            pageCount: null,
            pageNumber: 1,
            additionalImages: [],

            initialImage: null,
            signatureImage: null,
            initialModalShown: false,
            signatureModalShown: false,
            initialSecondaryModalShown: false,
            initialUtilityModalShown: false,
            signatureSecondaryModalShown: false,
            signatureUtilityModalShown: false,
            renderDrags: true,
            pdfCreated: false,
            notification: null,
            pdfUploaded: false,
            givenConsent: true,
            consentModalShown: false,
            scrollArrow: true,
            fieldWithArrow: null,
            groupWithArrow: null,
            width: 0,
            scale: 0,

            attemptingToSave: false,

            cosignerRequired: false,
            utilityHasNameOverride: false,
            leadName: '',
            utilityName: '',
            isGoodleap: false
        }
    }

    async componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', this.updateWindowDimensions)
        // Get params from router path
        const { proposalRecordID, prefixA, prefixB, dateCreated } = this.props.match.params
        const hiaSuffix = prefixA + '/' + prefixB

        if(prefixA == "good-leap"){
            this.setState({isGoodleap: true})
        }
        const data = await get({
            proposalRecordID,
            hiaSuffix,
            dateCreated,
        })



        // call Render PDF. Can only be called once. There is no switching now.

        
        const filteredClientActions = data.Fields
            .filter(f => ['Initial', 'Signature', 'Checkbox', 'FreeText'].includes(f.type))
            .sort((a, b) => a.top >= b.top ? 1 : -1)
            .sort((a, b) => a.pageNumber >= b.pageNumber ? 1 : -1)

        
        const groupIdsThatNeedClicked = []
        filteredClientActions.forEach((f, i) => {
            const groupId = f.meta && f.meta.groupId ? f.meta.groupId : null
            if (f.type == 'Checkbox' && f.value && groupId && !groupIdsThatNeedClicked.includes(groupId)) {
                groupIdsThatNeedClicked.push(groupId)
            }
        })


        filteredClientActions.forEach( (f, i) => {
            console.log({f, i})
            // another checkbox from this group has already been checked
            const groupId = f.meta && f.meta.groupId ? f.meta.groupId : null
            if (
                (
                    groupId && groupIdsThatNeedClicked.includes(groupId)
                ) ||
                (
                    f.type == 'Checkbox' && f.value
                )
            ){
                filteredClientActions[i] = {
                    ...f,
                    clicked: true
                }
            }
        })
        
        console.log({filteredClientActions})
        /*
        filteredClientActions.filter(f => {
            f.type == 'Checkbox' && f.meta && f.meta.groupId && f.value    
        }).
        forEach(f => {
            f.clicked = true
        })
        */
        
        this.setState(
            {
                clientActions: filteredClientActions,
                prefixA,
                prefixB,
                cosignerRequired: data.cosignerRequired,
                utilityHasNameOverride: data.utilityHasNameOverride,
                leadName: data.leadName,
                utilityName: data.utilityName,
                groups: data.Groups ? data.Groups : [],
            },
            async () => {
                await this.renderPDF(
                    data.DocumentData,
                    data.Fields.filter(f => f.type == 'QB'),
                )
            },
        )
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions)
    }

    updateWindowDimensions = () => {
        const width = window.innerWidth
        const height = window.innerHeight
        const scale = width <= 380 ? 0.55 : width <= 420 ? 0.65 : width <= 767 ? 1.2 : width <= 1200 ? 1.5 : 2
        this.setState({ width, height, scale })
    }

    renderPDF = async (rawDocBase64, dataToStamp) => {
        const arrayBuffer = base64ToArrayBuffer({ base64: rawDocBase64 })

        const pdfDoc = await PDFDocument.load(arrayBuffer)

        // Modify the pdfDoc
        const stampedPdfDoc = await stampWithData({
            pdfDoc,
            dataToStamp,
        })
        this.docBase64 = await stampedPdfDoc.saveAsBase64({ dataUri: true })
        this.setState({ pdfCreated: true })
    }

    onDocumentLoadSuccess = props => {
        const { numPages } = props
        const pageCount = numPages
        const clientActions = [...this.state.clientActions.filter(a => a.pageNumber <= pageCount)].filter(a => {
            if (this.state.cosignerRequired) return true
            else return a.meta.secondaryType != 'secondary'
        })
        this.setState({
            pageCount,
            clientActions,
        })
    }

    onPageOneLoadSuccess = ({ originalHeight, originalWidth }) => {
        this.setState({ originalWidth, originalHeight })
    }

    setNotification = ({ status, message, delayTime = 1500 }) => {
        this.setState(
            {
                notification: {
                    status,
                    message,
                },
            },
            () => {
                setTimeout(() => {
                    this.setState({ notification: null })
                }, delayTime)
            },
        )
    }

    savePDF = async () => {
        if(this.state.isGoodleap){
            alert('This is a walkthrough. Contract can not be saved')
        }
        else{
            alert('Unable to save. Please contact Sunobi immediately if you see this')
        }
    }

    // Mutate the state, to add the clicked property on the initials box,
    // so that then we can differentiate between initials that have been clicked on
    // or yet to confirm

    registerClick = id => {
        const { initialImage, signatureImage, additionalImages } = this.state
        if (!this.state.givenConsent) {
            this.setState({ consentModalShown: true })
            return
        }
        const fieldClicked = this.state.clientActions.find(f => f.id == id)
        
        // Booleans to make below conditionals nicer
        const initialClicked = fieldClicked.type == 'Initial'
        const signatureClicked = fieldClicked.type == 'Signature'
        const isSecondary = fieldClicked.meta && fieldClicked.meta.secondaryType == 'secondary'
        const additionalContainsInitial = additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary')
        const additionalContainsSignature = additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary')

        const others = this.state.clientActions.filter(f => f.id != id)
        fieldClicked.clicked = true

        if (this.state.givenConsent) {
            // Initials
            if (initialClicked) {
                if (!isSecondary && !initialImage) {
                    this.setState({initialModalShown: true})
                } else if ( isSecondary && !additionalContainsInitial) {
                    this.setState({initialSecondaryModalShown: true})
                }
            }
            // Signatures
            else if (signatureClicked) {
                if (!isSecondary && !signatureImage) {
                    this.setState({ signatureModalShown: true })
                } else if (isSecondary && !additionalContainsSignature) {
                    this.setState({
                        signatureSecondaryModalShown: true,
                    })
                }
            }
        }

        this.setState({ clientActions: [...others, fieldClicked] }, () => {
            if (
                !isSecondary && (
                    (initialClicked && this.state.initialImage) || (signatureClicked && this.state.signatureImage)
                )
            ){
                this.autoScroll()
            }
            if (
                isSecondary && (
                    (initialClicked && additionalContainsInitial ) ||
                    (signatureClicked && additionalContainsSignature)
                )
            )
                {
                this.autoScroll()
            }
        })
    }

    openConsentModal = () => {
        this.setState({ consentModalShown: true })
    }

    autoScroll = () => {
        this.setState({ scrollArrow: false })
        const yetToSign = this.state.clientActions
        .filter(a => !a.clicked)
        .filter(a => a.type != 'FreeText' || (a.type == 'FreeText' && a.meta && a.meta.required) )
        if (yetToSign.length) {
            const fieldToScrollTo = yetToSign[0]
            this.setState({ fieldWithArrow: fieldToScrollTo.id })
            if (fieldToScrollTo.type == "Checkbox" && fieldToScrollTo.meta && fieldToScrollTo.meta.groupId){
                this.setState({groupWithArrow: fieldToScrollTo.meta.groupId})
            }
            else{
                this.setState({groupWithArrow: null})
            }
            const scrollToElement = document.getElementById(fieldToScrollTo.id + '-scroll')
            scrollToElement.scrollIntoView({ behavior: 'smooth' })
        }
    }
    // This is the callback function provided to the modal for getting the image data
    // from the signature pad to update the global state
    updateImageFn = ({ type, data }) => {
        this.autoScroll()

        /* One of the non primary signatures
            initialSecondary
            signatureSecondary
            initialUtilityOverride
            signatureUtilityOverride
        */
        
        if (type.includes('Secondary') || type.includes('UtilityOverride')) {
            const newImage = { value: data }

            if (type.includes('initial')) newImage.type = 'initial'
            else newImage.type = 'signature'

            if (type.includes('Secondary')) newImage.secondaryType = 'secondary'
            else newImage.secondaryType = 'utilityOverride'

            // Get current additionalImages and filter out for copies of same type
            const additionalImages = this.state.additionalImages.filter(
                i => !(i.type == newImage.type && i.secondaryType == newImage.secondaryType),
            )
            additionalImages.push(newImage)
            this.setState({ additionalImages })
        } else {
            // This a standard primary signature
            this.setState({ [type + 'Image']: data })
        }
    }
    capitalize = s => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    scrollOffsetStyles(f) {
        const scrollOffset = 280
        return {
            opacity: 0,
            position: 'absolute',
            left: f.left * this.state.scale + 'px',
            top: (f.top - scrollOffset) * this.state.scale + 'px',
        }
    }

    initialFieldStyles(f) {
        const { additionalImages, initialImage, scale } = this.state
        let hasImage = false
        if (
            f.meta.secondaryType == 'secondary' &&
            additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary')
        ) {
            hasImage = true
        } else if (f.meta.secondaryType != 'secondary') {
            hasImage = initialImage && initialImage.length > 0
        }
        return {
            position: 'absolute',
            left: f.left * scale + 'px',
            top: f.top * scale + 'px',
            height: 40 * scale + 'px',
            width: 51 * scale + 'px',
            transform: '1s',
            borderColor: hasImage && f.clicked ? 'transparent' : '',
            backgroundColor: hasImage && f.clicked ? 'transparent' : '',
        }
    }
    signatureFieldStyles(f) {
        // Remember about utility logic in here
        const { additionalImages, signatureImage, scale } = this.state
        let hasImage = false
        if (
            f.meta.secondaryType == 'secondary' &&
            additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary')
        ) {
            hasImage = true
        } else if (f.meta.secondaryType != 'secondary') {
            hasImage = signatureImage && signatureImage.length > 0
        }
        return {
            position: 'absolute',
            left: f.left * scale + 'px',
            top: f.top * scale + 'px',
            height: 53 * scale + 'px',
            width: 73 * scale + 'px',
            transform: '1s',
            borderColor: hasImage && f.clicked ? 'transparent' : '',
            backgroundColor: hasImage && f.clicked ? 'transparent' : '',
        }
    }
    arrowStyles(f) {
        let offset = 12;
        switch(f.type){
            case 'Initial':
                offset = 6
                break;
            case 'FreeText':
                offset = -5
                break;
            case 'Checkbox':
                offset = -5
                break;
        }


        return {
            width: this.state.scale * 45 + 'px',
            position: 'absolute',
            left: (f.left - 45) * this.state.scale + 'px',
            top: (f.top + offset) * this.state.scale + 'px',
        }
    }

    renderInitialAction(f) {
        const { scale, initialImage, additionalImages, givenConsent } = this.state
        const scalableFontSize = 7 * this.state.scale + 'px'
        const initialImageStyles = {
            width: 40 * this.state.scale + 'px',
        }
        const editTextStyles = {
            position: 'absolute',
            bottom: -13 * this.state.scale + 'px',
            fontSize: scalableFontSize,
        }
        let modalToShow = 'initialModalShown'
        let image = ''
        if (
            f.meta.secondaryType == 'secondary' &&
            additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary')
        ) {
            image = additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary').value
            modalToShow = 'initialSecondaryModalShown'
        } else if (f.meta.secondaryType != 'secondary' && initialImage) {
            image = initialImage
        }

        // This has been clicked on!
        if (f.clicked && image.length > 0) {
            return (
                <>
                    <img src={image} style={initialImageStyles} />
                    <EditText
                        style={editTextStyles}
                        onClick={() =>
                            this.setState({
                                [modalToShow]: true,
                            })
                        }
                    >
                        Edit
                    </EditText>
                </>
            )
        }

        // This is a secondary Type

        return (
            <SignText
                style={{
                    fontSize: scalableFontSize,
                }}
            >
                {f.meta.secondaryType == 'secondary'
                    ? additionalImages.find(i => i.type == 'initial' && i.secondaryType == 'secondary')
                        ? 'Click Co-Initials'
                        : 'Sign Co-Initials'
                    : initialImage
                    ? `Click Initials`
                    : `Sign Initials`}
            </SignText>
        )
    }

    renderSignatureAction(f) {
        const { scale, signatureImage, additionalImages, givenConsent } = this.state

        const scalableFontSize = 8 * scale + 'px'
        const signatureImageStyles = {
            width: 60 * scale + 'px',
        }
        const editTextStyles = {
            position: 'absolute',
            bottom: -13 * scale + 'px',
            fontSize: scalableFontSize,
        }

        let image = ''
        let modalToShow = 'signatureModalShown'

        if (
            f.meta.secondaryType == 'secondary' &&
            additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary')
        ) {
            image = additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary').value
            modalToShow = 'signatureSecondaryModalShown'
        } else if (f.meta.secondaryType != 'secondary' && signatureImage) {
            image = signatureImage
        }

        // This has been clicked on!
        if (f.clicked && image.length > 0) {
            return (
                <>
                    <img src={image} style={signatureImageStyles} />
                    <EditText
                        style={editTextStyles}
                        onClick={() =>
                            this.setState({
                                [modalToShow]: true,
                            })
                        }
                    >
                        Edit
                    </EditText>
                </>
            )
        }

        // No images exist
        return (
            <SignText
                style={{
                    fontSize: scalableFontSize,
                }}
            >
                {f.meta.secondaryType == 'secondary'
                    ? additionalImages.find(i => i.type == 'signature' && i.secondaryType == 'secondary')
                        ? 'Click Co-Signature'
                        : 'Sign Co-Signature'
                    : signatureImage
                    ? `Click Signature`
                    : `Sign Signature`}
            </SignText>
        )
    }

    checkboxClick = (id) => {
        
        const { clientActions, groups } = this.state
        const specificCheckbox = clientActions.find(f => f.id == id)
        const relatedGroupId = specificCheckbox.meta && specificCheckbox.meta.groupId ? specificCheckbox.meta.groupId : null

        const specificGroup = relatedGroupId ? groups.find(g => g.id == relatedGroupId): null

        console.log({ specificCheckbox, specificGroup, groups})

        specificCheckbox.clicked = specificCheckbox.value ? false : true
        specificCheckbox.value = !specificCheckbox.value
        const updatedCheckboxes = [specificCheckbox]

        if (specificGroup && specificGroup.type == 'OneRequired'){
            // Need to unclick all of the other checkboxes in the group
            updatedCheckboxes.push(
                ...clientActions
                .filter(
                    f => f.id != id && f.meta && f.meta.groupId == relatedGroupId
                )
                .map(f => {
                    f.clicked = true
                    if (f.type == "Checkbox") {
                        f.value = false
                    }
                    else if (f.type == "FreeText") {
                        f.value = ''
                    }
                    return f
                })
            )
        }
        else if (specificGroup && specificGroup.type == "AnyRequired" && specificCheckbox.clicked){
            updatedCheckboxes.push(
                ...clientActions
                .filter(
                    f => f.id != id && f.meta && f.meta.groupId == relatedGroupId
                )
                .map(f => {
                    f.clicked = true
                    return f
                })
            )
        }

        

        const updatedCheckboxIds = updatedCheckboxes.map(c => c.id)
        console.log({ updatedCheckboxIds, updatedCheckboxes, clientActions })
        this.setState({ 
            clientActions: [
                ...clientActions.filter(f => !updatedCheckboxIds.includes(f.id)),
                ...updatedCheckboxes 
            ] 
        }, () => {
            if(specificCheckbox.clicked){
                this.autoScroll()
            }
        })
    }

    updateFreeText = (id, value) => {
        const { clientActions } = this.state
        const specificField = clientActions.find(f => f.id == id)
        specificField.value = value
        specificField.clicked = true

        this.setState({
            clientActions: [
                ...clientActions.filter(f => f.id != specificField.id),
                specificField,
            ]
        }, () => {
            // if the user has stopped inputting text, then scroll to the next field
            setTimeout(() => {
                const fieldFromState = this.state.clientActions.find(f => f.id == id)
                if (fieldFromState && fieldFromState.value == value) {
                    this.autoScroll()
                }
            }, 2000)
            
        })

    }

    render() {

        const { 
            originalWidth,
            originalHeight,
            scale,
            clientActions,
            givenConsent,
            fieldWithArrow,
            groupWithArrow,
            leadName,
            utilityNameOverride,
            utilityHasNameOverride,
            cosignerRequired,
            checkboxes,
            freeTexts,
        } = this.state

        let primaryName = ''
        let secondaryName = ''
        const nameArray = leadName.split(',')
        if (nameArray.length > 1) {
            primaryName = nameArray[0].trim()
            secondaryName = nameArray[1].trim()
        }
        const viewerStyles = {
            overflowY: 'scroll',
            display: 'flex',
            justifyContent: 'center',
            marginTop: this.state.width < 420 ? '85px' : '50px',
            width: '100vw',
            opacity: this.state.givenConsent ? 1 : 0.4,
            backgroundColor: '#fff',
        }

        return (
            <>
                <Helmet>
                    <title>
                        {this.state.prefixA
                            ? this.capitalize(this.state.prefixA) +
                              ' ' +
                              this.capitalize(this.state.prefixB) +
                              ' Document Signing | Sunobi'
                            : 'Sunobi Document Signing'}
                    </title>
                </Helmet>
                {this.state.notification ? (
                    <Notification status={this.state.notification.status} message={this.state.notification.message} />
                ) : (
                    <></>
                )}
                {this.state.pdfUploaded ? (
                    <Thanks />
                ) : (
                    <>  
                            
                        <SignHeader
                            clientActions={this.state.clientActions}
                            saveFn={this.savePDF}
                            autoScrollFn={this.autoScroll}
                            width={this.state.width}
                            givenConsent={this.state.givenConsent}
                            consentModalShown={this.state.consentModalShown}
                            openConsentFn={this.openConsentModal}
                            scrollArrow={this.state.scrollArrow}
                            initialModalShown={this.state.initialModalShown}
                            signatureModalShown={this.state.signatureModalShown}
                            scale={this.state.scale}
                            attemptingToSave={this.state.attemptingToSave}
                            isGoodleap={this.state.isGoodleap}
                            groups={this.state.groups}
                        />
                        <Wrapper>
                            <Container style={{ backgroundColor: this.state.pdfCreated ? '#888' : '#fff' }}>
                                {this.state.pdfCreated ? (
                                    <div style={viewerStyles} id="viewer">
                                        <Document file={this.docBase64} onLoadSuccess={this.onDocumentLoadSuccess}>
                                            {[...Array(this.state.pageCount)].map((_, pageIndex) => (
                                                <div style={{ position: 'relative' }} key={pageIndex + '-div'}>
                                                    {pageIndex === 0 ? (
                                                        <Page
                                                            pageNumber={pageIndex + 1}
                                                            scale={scale}
                                                            key={pageIndex + '-page'}
                                                            onRenderSuccess={this.onPageOneLoadSuccess}
                                                            //Performance benefits
                                                            renderAnnotationLayer={false}
                                                            renderInteractiveForms={false}
                                                            renderTextLayer={false}
                                                        />
                                                    ) : (
                                                        <WrappedPage
                                                            pageNumber={pageIndex + 1}
                                                            scale={scale}
                                                            key={pageIndex + '-page'}
                                                            originalWidth={originalWidth}
                                                            originalHeight={originalHeight}
                                                            isGoodleap={this.state.isGoodleap}
                                                        />
                                                    )}

                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                        }}
                                                    >
                                                        {clientActions
                                                            .filter(
                                                                f =>
                                                                    f.pageNumber == pageIndex + 1 &&
                                                                    (f.type == 'Initial' || f.type == 'Signature'),
                                                            )
                                                            .map(f => (
                                                                <Fragment key={f.id}>
                                                                    <div id={`${f.id}-scroll`} style={this.scrollOffsetStyles(f)}></div>
                                                                    {!f.clicked && givenConsent && f.id == fieldWithArrow ? <img src={rightArrow} style={this.arrowStyles(f)}/> : <></>}
                                                                    <SignField
                                                                        style={
                                                                            f.type == 'Initial'
                                                                                ? this.initialFieldStyles(f)
                                                                                : this.signatureFieldStyles(f)
                                                                        }
                                                                        onClick={() => this.registerClick(f.id)}
                                                                        key={`${f.id}`}
                                                                    >
                                                                        {f.type == 'Initial'
                                                                            ? this.renderInitialAction(f)
                                                                            : this.renderSignatureAction(f)}
                                                                    </SignField>
                                                                </Fragment>
                                                            ))
                                                        }
                                                        {
                                                            clientActions
                                                            .filter(f => f.pageNumber == pageIndex + 1 && f.type == 'Checkbox')
                                                            .map(f => {
                                                                const divStyles = {
                                                                    position: 'absolute',
                                                                    left: f.left * scale + 'px',
                                                                    top: f.top * scale + 'px',
                                                                    height: 15 * scale + 'px',
                                                                    width: 15 * scale + 'px',
                                                                    transform: '1s',
                                                                    cursor: 'pointer',
                                                                } 

                                                                return (
                                                                    <Fragment key={f.id}>
                                                                        <div id={`${f.id}-scroll`} style={this.scrollOffsetStyles(f)}></div>
                                                                        {!f.clicked && givenConsent && f.meta && f.meta.groupId == groupWithArrow && !f.meta.hide ? <img src={rightArrow} style={this.arrowStyles(f)}/> :  <></> }
                                                                        <div style={divStyles} onClick={() => this.checkboxClick(f.id)}>
                                                                            <img 
                                                                                src={f.value ? consentGiven : consentCheckbox} 
                                                                                style={{ width: f.value ? '100%': "80%", pointerEvents: 'none'}}
                                                                            />
                                                                            {!f.clicked ? <CheckboxIndicator style={{height: "85%"}}/>: <></>}
                                                                        </div>
                                                                    </Fragment>
                                                            )
                                                        })
                                                        }
                                                        {clientActions.filter(
                                                            f => f.pageNumber == pageIndex + 1 && f.type == 'FreeText',
                                                        ).map(f => {
                                                            // This needs to come from meta
                                                            const freeTextStyles = {
                                                                position: 'absolute',
                                                                left: f.left * scale + 'px',
                                                                top: f.top * scale + 'px',
                                                                width: 100 * scale + 'px',
                                                                transform: '1s',
                                                                cursor: 'pointer',
                                                                borderColor: 'black',
                                                                borderWidth: '1px',
                                                                borderRadius: "5px"
                                                            }
                                                            const inputStyles = {
                                                                zIndex: 10,
                                                            }

                                                            if (f.meta) {
                                                                if (f.meta.maxWidth) {
                                                                    freeTextStyles.width = f.meta.maxWidth * scale + 'px'
                                                                }
                                                                if (f.meta.fontSize) {
                                                                    inputStyles.fontSize = f.meta.fontSize * scale + 'px'
                                                                }
                                                                if (f.meta.color) {
                                                                    inputStyles.color = 'rgb(' + f.meta.color + ')'
                                                                }
                                                            }

                                                            return (
                                                                <Fragment key={f.id}>
                                                                    <div id={`${f.id}-scroll`} style={this.scrollOffsetStyles(f)}></div>
                                                                    {!f.clicked && givenConsent && f.id == fieldWithArrow ? <img src={rightArrow} style={this.arrowStyles(f)}/> : <></> }
                                                                    <div style={freeTextStyles}>
                                                                        <Input
                                                                            isRequired={f.meta.required}
                                                                            value={f.value} 
                                                                            onChange={(e) => this.updateFreeText(f.id, e.target.value)} 
                                                                            style={inputStyles}
                                                                            placeholder={f.meta.required ? 'Required Text': 'Text'}
                                                                        />
                                                                        
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                        </Document>
                                    </div>
                                ) : (
                                    <p>Loading</p>
                                )}
                            </Container>
                                {
                                    this.state.isGoodleap ? <DemoMode /> : <></>
                                }
                        </Wrapper>
                        
                        <Modal
                            type={'initial'}
                            show={this.state.initialModalShown}
                            hide={() => this.setState({ initialModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={cosignerRequired || utilityHasNameOverride}
                            name={primaryName}
                        />

                        <Modal
                            type={'signature'}
                            show={this.state.signatureModalShown}
                            hide={() => this.setState({ signatureModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={cosignerRequired || utilityHasNameOverride}
                            name={primaryName}
                        />

                        <Modal
                            type={'initialSecondary'}
                            show={this.state.initialSecondaryModalShown}
                            hide={() => this.setState({ initialSecondaryModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={cosignerRequired}
                            name={secondaryName}
                        />
                        <Modal
                            type={'signatureSecondary'}
                            show={this.state.signatureSecondaryModalShown}
                            hide={() => this.setState({ signatureSecondaryModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={cosignerRequired}
                            name={secondaryName}
                        />

                        <Modal
                            type={'initialUtilityOverride'}
                            show={this.state.initialUtilityModalShown}
                            hide={() => this.setState({ initialUtilityModalShown: false })}
                            updateImage={this.updateImageFn}
                            showName={utilityHasNameOverride}
                            name={utilityNameOverride}
                        />
                        <Modal
                            type={'signatureUtilityOverride'}
                            show={this.state.signatureUtilityModalShown}
                            hide={() => this.setState({ signatureUtilityModalShown: false })}
                            showName={utilityHasNameOverride}
                            name={utilityNameOverride}
                        />

                        <ConsentModal
                            show={this.state.consentModalShown}
                            hide={() => this.setState({ consentModalShown: false })}
                            confirm={() => this.setState({ givenConsent: true, consentModalShown: false })}
                            scale={this.state.scale}
                        />
                    </>
                )}
            </>
        )
    }
}

const Wrapper = tw.div`
  flex flex-col items-center justify-center h-screen
`
const Container = tw.div`
  flex flex-col justify-center h-full space-y-5
`

const SignField = tw.div`
    absolute
    w-auto
    rounded-md
    flex
    flex-col
    items-center
    justify-center
    border-green-300
    bg-green-100
    bg-opacity-50
    cursor-pointer
    hover:bg-opacity-70
`
const SignText = tw.p`
    text-gray-700
    text-xs
    font-medium
`
const EditText = tw.a`
    absolute
    text-xs
    text-red-500
    font-light
    uppercase
`

const CheckboxIndicator = tw.div`
    absolute
    top-0
    border-green-300
    bg-green-100
    bg-opacity-50
    cursor-pointer
    hover:bg-opacity-70
    h-full
    w-8/10
`

const Input = styled.input( ({ isRequired = false }) => [
    tw`w-full rounded py-1 px-2`,
    isRequired && tw `
        border-red-300
        bg-opacity-50
        bg-red-100
    `,
    !isRequired && tw `
        border-green-300 
        bg-green-100
        bg-opacity-50
    `
])


export default withRouter(App)
