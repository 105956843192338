import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
import { render } from 'react-dom'
import { GlobalStyles } from 'twin.macro'
import global from './global.css'
import Edit from './Edit'
import EditV2 from './EditV2'
import Sign from './Sign'

import Multiple from './Multiple'
import Thanks from './components/Thanks'
import NotFound from './components/NotFound'
import smoothscroll from 'smoothscroll-polyfill'
import { Helmet } from 'react-helmet'
import { CookiesProvider } from 'react-cookie';

import { pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
smoothscroll.polyfill()

render(
    <CookiesProvider defaultSetCookies={{ path: '/' }}>
        <Router>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sunobi Documents</title>
            </Helmet>
            <GlobalStyles />
            <Switch>
                <Route path="/:proposalRecordID/multiple/:types/sign/:dateCreated" children={<Multiple />} />
                <Route path="/:proposalRecordID/:prefixA/:prefixB/edit/:dateCreated" children={<Edit />} />
                <Route path="/:proposalRecordID/:prefixA/:prefixB/sign/:dateCreated" children={<Sign />} />
                <Route path="/editV2/:contractId/:proposalRecordId/" children={<EditV2 />} />
                <Route path="/thanks/" children={<Thanks />} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    </CookiesProvider>,
    document.getElementById('root'),
)
