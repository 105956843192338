import React, { Component } from 'react' // eslint-disable-line
import {
    ModalBlock,
    Container,
    ButtonContainer,
    Button,
    Label,
    HeaderLabel,
    Input,
    Select
} from './AdvancedModal.styles'

class GroupsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            newLabel : "",
            activeId: null,
            activeType: null,
        }
    }


    render() {
        const { hideComponent, hide, groups, createGroupFn, editGroupFn, deleteGroupFn, clientActions } = this.props
        const activeGroup = groups.find(g => g.id == this.state.activeId)
        const {activeType} = this.state
        return (
            <ModalBlock
                role="dialog"
                style={{
                    minWidth: '150px',
                    top: '80px',
                    left: '20px',
                    position: 'fixed',
                    zIndex: 8888,
                }}
            >
                <div style={{ position: 'absolute', top: -10, right: -10 }}>
                    {hideComponent(() => {
                        hide()
                    })}
                </div>
                <Container>
                    <Label>New Group Label</Label>

                    <Input
                        onChange={e => {
                            const { value } = e.target
                            this.setState({ newLabel: value })
                        }}
                        type="text"
                        value={this.state.newLabel}
                    />
                    <Button type="button" onClick={() => {
                        createGroupFn({
                            id: Math.floor(Math.random() * 200000000),
                            label: this.state.newLabel,
                            type: "OneRequired",
                        })
                        this.setState({newLabel: ""})
                    }}>
                        Create a new group
                    </Button>
                    <Label>OR edit</Label>
                    <Label>Select A Group</Label>
                    <Select
                        onChange={e => {
                            console.log({e})
                            const { value } = e.target
                            this.setState({ activeId: value })
                        }}
                    >
                        <option value="">Choose Group</option>
                        {groups && groups.map(g => (
                            <option key={g.id} value={g.id}>
                                {g.label}
                            </option>
                        ))}
                    </Select>
                    
                    {activeGroup ? 
                        <>
                            <Label>Type Of Group</Label>
                            <Select
                                onChange={e => {
                                    const { value } = e.target
                                    this.setState({ activeType: value })
                                }}
                                value={activeType ? activeType : activeGroup.type}
                            >   
                                <option value="">Choose Type</option>
                                <option value="OneRequired">One Required</option>
                                <option value="AnyRequired">One or more required</option>
                                <option value="NotRequied">Not Required / Optional</option>
                            </Select>
                            <ButtonContainer>
                                <Button type="button" onClick={() => {
                                    const {id, label} = activeGroup
                                    editGroupFn({
                                        id,
                                        label,
                                        type: activeType,
                                    })
                                    this.setState({ activeType: null})
                                }}>
                                    Edit
                                </Button>
                                <Button type="button" onClick={() => {
                                    const foundClientActions = clientActions.filter(ca => ca.meta && ca.meta.groupId == activeGroup.id)
                                    if(foundClientActions.length > 0) {
                                        alert("Cannot delete group with client actions")
                                        return
                                    }
                                    deleteGroupFn(activeGroup.id)
                                }}>
                                    Delete
                                </Button>
                            </ButtonContainer>
                        </>
                        : 
                        <></>

                    }
                
                </Container>
                
            </ModalBlock>
        )
    }
}

export default GroupsModal
